<template>
  <v-list-item
    class="w-full"
    :style="{ minHeight }"
    :value="method"
    tag="button"
    tabindex="0"
  >
    <v-list-item-content>
      <payout-method-card :method="method" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import PayoutMethodCard from "@/components/payout-methods/PayoutMethodCard";

export default {
  name: "PayoutMethodSelectOption",

  components: { PayoutMethodCard },

  props: {
    method: { type: Object, required: true },
    minHeight: { type: String, default: "60px" },
  },
};
</script>
