<template>
  <div>
    <component :is="$route.meta.layout || 'div'" />
    <vue-announcer />
  </div>
</template>

<script>
import { useAppStore } from "@/stores/app";
export default {
  name: "App",

  setup() {
    const appStore = useAppStore();
    appStore.resetTraceId();
    // const srcPath = computed(
    //   () =>
    //     process.env.VUE_APP_DDP_ASSETS_URL +
    //     "/notificationscript/cookienotification.js"
    // );

    //  const cookieNotificationEnabled = computed(() => {
    //    return process.env.VUE_APP_COOKIE_NOTIFICATION === "true";
    //  });
    return { appStore }; // srcPath, cookieNotificationEnabled
  },
  mounted() {
    this.appStore.setTraceId();
    //Code block to test integration of cookie notification js
    // let cookieScript = document.createElement("script");
    // cookieScript.src = this.srcPath;
    // document.head.appendChild(cookieScript);
  },
};
</script>
