import { i18n } from "@/plugins/i18n";

export default [
  {
    name: i18n.global.t("states.alabama"),
    abbreviation: "AL",
  },
  {
    name: i18n.global.t("states.alaska"),
    abbreviation: "AK",
  },
  {
    name: i18n.global.t("states.americanSamoa"),
    abbreviation: "AS",
  },
  {
    name: i18n.global.t("states.arizona"),
    abbreviation: "AZ",
  },
  {
    name: i18n.global.t("states.arkansas"),
    abbreviation: "AR",
  },
  {
    name: i18n.global.t("states.california"),
    abbreviation: "CA",
  },
  {
    name: i18n.global.t("states.colorado"),
    abbreviation: "CO",
  },
  {
    name: i18n.global.t("states.connecticut"),
    abbreviation: "CT",
  },
  {
    name: i18n.global.t("states.delaware"),
    abbreviation: "DE",
  },
  {
    name: i18n.global.t("states.districtOfColumbia"),
    abbreviation: "DC",
  },
  {
    name: i18n.global.t("states.federatedStatesOfMicronesia"),
    abbreviation: "FM",
  },
  {
    name: i18n.global.t("states.florida"),
    abbreviation: "FL",
  },
  {
    name: i18n.global.t("states.georgia"),
    abbreviation: "GA",
  },
  {
    name: i18n.global.t("states.guam"),
    abbreviation: "GU",
  },
  {
    name: i18n.global.t("states.hawaii"),
    abbreviation: "HI",
  },
  {
    name: i18n.global.t("states.idaho"),
    abbreviation: "ID",
  },
  {
    name: i18n.global.t("states.illinois"),
    abbreviation: "IL",
  },
  {
    name: i18n.global.t("states.indiana"),
    abbreviation: "IN",
  },
  {
    name: i18n.global.t("states.iowa"),
    abbreviation: "IA",
  },
  {
    name: i18n.global.t("states.kansas"),
    abbreviation: "KS",
  },
  {
    name: i18n.global.t("states.kentucky"),
    abbreviation: "KY",
  },
  {
    name: i18n.global.t("states.louisiana"),
    abbreviation: "LA",
  },
  {
    name: i18n.global.t("states.maine"),
    abbreviation: "ME",
  },
  {
    name: i18n.global.t("states.marshallIslands"),
    abbreviation: "MH",
  },
  {
    name: i18n.global.t("states.maryland"),
    abbreviation: "MD",
  },
  {
    name: i18n.global.t("states.massachusetts"),
    abbreviation: "MA",
  },
  {
    name: i18n.global.t("states.michigan"),
    abbreviation: "MI",
  },
  {
    name: i18n.global.t("states.minnesota"),
    abbreviation: "MN",
  },
  {
    name: i18n.global.t("states.mississippi"),
    abbreviation: "MS",
  },
  {
    name: i18n.global.t("states.missouri"),
    abbreviation: "MO",
  },
  {
    name: i18n.global.t("states.montana"),
    abbreviation: "MT",
  },
  {
    name: i18n.global.t("states.nebraska"),
    abbreviation: "NE",
  },
  {
    name: i18n.global.t("states.nevada"),
    abbreviation: "NV",
  },
  {
    name: i18n.global.t("states.newHampshire"),
    abbreviation: "NH",
  },
  {
    name: i18n.global.t("states.newJersey"),
    abbreviation: "NJ",
  },
  {
    name: i18n.global.t("states.newMexico"),
    abbreviation: "NM",
  },
  {
    name: i18n.global.t("states.newYork"),
    abbreviation: "NY",
  },
  {
    name: i18n.global.t("states.northCarolina"),
    abbreviation: "NC",
  },
  {
    name: i18n.global.t("states.northDakota"),
    abbreviation: "ND",
  },
  {
    name: i18n.global.t("states.northernMarianaIslands"),
    abbreviation: "MP",
  },
  {
    name: i18n.global.t("states.ohio"),
    abbreviation: "OH",
  },
  {
    name: i18n.global.t("states.oklahoma"),
    abbreviation: "OK",
  },
  {
    name: i18n.global.t("states.oregon"),
    abbreviation: "OR",
  },
  {
    name: i18n.global.t("states.palau"),
    abbreviation: "PW",
  },
  {
    name: i18n.global.t("states.pennsylvania"),
    abbreviation: "PA",
  },
  {
    name: i18n.global.t("states.puertoRico"),
    abbreviation: "PR",
  },
  {
    name: i18n.global.t("states.rhodeIsland"),
    abbreviation: "RI",
  },
  {
    name: i18n.global.t("states.southCarolina"),
    abbreviation: "SC",
  },
  {
    name: i18n.global.t("states.southDakota"),
    abbreviation: "SD",
  },
  {
    name: i18n.global.t("states.tennessee"),
    abbreviation: "TN",
  },
  {
    name: i18n.global.t("states.texas"),
    abbreviation: "TX",
  },
  {
    name: i18n.global.t("states.utah"),
    abbreviation: "UT",
  },
  {
    name: i18n.global.t("states.vermont"),
    abbreviation: "VT",
  },
  {
    name: i18n.global.t("states.virginIslands"),
    abbreviation: "VI",
  },
  {
    name: i18n.global.t("states.virginia"),
    abbreviation: "VA",
  },
  {
    name: i18n.global.t("states.washington"),
    abbreviation: "WA",
  },
  {
    name: i18n.global.t("states.westVirginia"),
    abbreviation: "WV",
  },
  {
    name: i18n.global.t("states.wisconsin"),
    abbreviation: "WI",
  },
  {
    name: i18n.global.t("states.wyoming"),
    abbreviation: "WY",
  },
  {
    name: i18n.global.t("states.apoEurope"),
    abbreviation: "AE",
  },
  {
    name: i18n.global.t("states.apoAmericas"),
    abbreviation: "AA",
  },
  {
    name: i18n.global.t("states.apoPacific"),
    abbreviation: "AP",
  },
];
