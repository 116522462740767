import { createRouter, createWebHashHistory } from "vue-router";
import routes from "@/router/routes";
import getFlattedRoutes from "@/router/flatten";
import { deselect, updateTitle } from "@/router/guards";

const flattened = getFlattedRoutes(routes);

const router = createRouter({
  history: createWebHashHistory(),
  routes: flattened,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(updateTitle);
router.afterEach(deselect);

export default router;
