<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex pt-3 px-3 primary--text"
      data-test="add-coin-base-info-section"
    >
      <coinbase-logo
        data-test="coin-base-logo-svg"
        height="65px"
        width="111px"
        min-width="65px"
        class="mr-2"
      />
    </div>

    <v-form
      ref="form"
      v-model="isFormValid"
      :disabled="disableForm"
      class="pt-6"
    >
      <v-row v-if="!isEditing" class="px-3 px-sm-4">
        <v-col cols="12" sm="6">
          <name-text-field
            v-model="firstName"
            :label="$t('CoinBaseForm.firstName')"
            maxlength="75"
            data-test="add-coin-base-first-name"
            :disabled="isEditing"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <name-text-field
            v-model="lastName"
            :label="$t('CoinBaseForm.lastName')"
            data-test="add-coin-base-last-name"
            :disabled="isEditing"
          />
        </v-col>
      </v-row>

      <v-row class="px-3 px-sm-4">
        <v-col cols="12" sm="6">
          <secure-text-field
            v-model.trim="accountNumber"
            :label="$t('CoinBaseForm.accountNumber')"
            :rules="[required(), betweenNumeric(3, 30)]"
            maxlength="30"
            data-test="add-coin-base-account-number"
            :disabled="isEditing"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <secure-text-field
            v-model.trim="confirmAccountNumber"
            :label="$t('CoinBaseForm.confirmAccountNumber')"
            :rules="[required(), betweenNumeric(3, 30), accountNumbersMatch]"
            data-test="add-coin-base-confirm-account-number"
            :disabled="isEditing"
            @paste.prevent
          />
        </v-col>
      </v-row>

      <v-row class="px-3 px-sm-4">
        <v-col cols="12" sm="6">
          <app-text-field
            v-model="routingNumber.value"
            v-maska:[options]
            :label="$t('CoinBaseForm.routingNumber')"
            :rules="[required()]"
            maxlength="9"
            :loading="routingNumberLoading"
            data-test="add-coin-base-routing-number"
            :disabled="true"
          >
            <template #prepend-inner>
              <v-tooltip location="top" max-width="300" :open-on-click="true">
                <template #activator="{ props }">
                  <svg-icon
                    type="mdi"
                    :path="mdiInformation"
                    v-bind="props"
                    class="cursor-pointer"
                    data-test="routing-number-help-icon"
                  >
                  </svg-icon>
                </template>
                <div
                  class="d-flex flex-column flex-wrap align-center text-center"
                >
                  <div class="pb-4 px-4">
                    {{ $t("CoinBaseForm.routingNumberToolTip") }}
                  </div>
                  <div class="d-flex align-center">
                    <v-img
                      src="@/assets/img/Blank_Check_Help.png"
                      max-height="150"
                      max-width="250"
                    />
                  </div>
                  <div class="d-flex font-weight-bold">
                    <div
                      class="blank-check-tool-tip px-1 mr-1 ml-1 routing-account-width"
                    >
                      {{ $t("CoinBaseForm.routingOrTransitNumber") }}
                    </div>
                    <div
                      class="blank-check-tool-tip px-1 mr-1 routing-account-width"
                    >
                      {{ $t("CoinBaseForm.accountNumber") }}
                    </div>
                    <div class="blank-check-tool-tip px-1">
                      {{ $t("CoinBaseForm.checkNumber") }}
                    </div>
                  </div>
                </div>
              </v-tooltip>
            </template>
          </app-text-field>
        </v-col>
      </v-row>

      <div class="d-flex pt-5 pt-sm-0 px-3 px-sm-4">
        <terms-and-conditions-checkbox
          v-model="acceptTermsAndConditions"
          data-test="add-coin-base-terms-and-conditions"
          class="payoutTermsCondition"
        />
      </div>

      <div v-if="transient" class="d-flex pt-5 pt-sm-0 px-3 px-sm-4">
        <v-checkbox
          v-if="!isGuest"
          v-model="savePayoutAccount"
          class="customized_info"
          :error-messages="errorSavedPayoutMethod"
          :label="$t('PayoutsAvailable.savePayoutAccount')"
          data-test="save-payout-method"
          aria-required="true"
          :disabled="savedPayoutMethods.length > 4"
        >
        </v-checkbox>
      </div>

      <div v-if="!transient" class="d-flex px-3 px-sm-4">
        <v-checkbox
          v-model="isPrimaryMethod"
          :label="$t('CoinBaseForm.defaultPayoutMethod')"
          :disabled="isEditing && selectedPayoutMethod.coinbase.default"
          data-test="add-coin-base-primary-method"
        />
      </div>

      <div class="d-flex justify-center align-center pt-10">
        <v-btn
          depressed
          tile
          :disabled="disburseLoading || disableForm"
          :loading="disburseLoading || persistLoading"
          color="primary"
          class="mx-2"
          :class="{
            custom_min_width_100: isEditing || transient,
            custom_min_width_150: !(isEditing || transient),
          }"
          data-test="add-coin-base-add-account"
          @click="submit"
        >
          {{
            isEditing || transient
              ? $t("CoinBaseForm.submit")
              : $t("CoinBaseForm.addAccount")
          }}
        </v-btn>
        <v-btn
          variant="outlined"
          tile
          class="mx-2 custom_min_width_100"
          data-test="add-coin-base-cancel"
          @click="showConfirmCancel = true"
        >
          {{ $t("CoinBaseForm.cancel") }}
        </v-btn>
      </div>
      <div v-if="disableForm" class="d-flex px-3 pt-6 px-sm-4">
        <v-alert type="error" tile>
          <span data-test="disburse-error-message">
            {{ disburseError }}
          </span>
        </v-alert>
      </div>
    </v-form>

    <v-divider class="my-7"></v-divider>
    <div class="my-7 d-flex align-center flex-column" data-test="header">
      <div class="primary--text text-center">
        {{ $t("CoinBaseForm.needAcoinWallet") }}
      </div>
      <a
        href="https://www.coinbase.com/"
        target="_blank"
        class="text-decoration-none"
      >
        <v-btn
          tile
          variant="outlined"
          class="my-5"
          color="primary"
          data-test="cancel-btn"
        >
          {{ $t("CoinBaseForm.newCoinbaseWallet") }}
        </v-btn>
      </a>
      <div class="primary--text text-subtitle-2 text-center">
        {{ $t("CoinBaseForm.disclaimerCoinbase") }}
      </div>
    </div>

    <payout-methods-confirm-cancel-dialog
      v-model="showConfirmCancel"
      :editing="isEditing"
    />
  </div>
</template>

<script>
import PayoutMethodsConfirmCancelDialog from "@/components/payout-methods/PayoutMethodsConfirmCancelDialog";
import { mdiAlert, mdiCheck, mdiInformation } from "@mdi/js";
import AppTextField from "@/components/forms/AppTextField";
import NameTextField from "@/components/forms/NameTextField";
import SecureTextField from "@/components/forms/SecureTextField";
import isEqual from "lodash/isEqual";
import TermsAndConditionsCheckbox from "@/components/forms/TermsAndConditionsCheckbox";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import { usePayoutStore } from "@/stores/payout";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { i18n } from "@/plugins/i18n";
import { useTitle } from "@/composables/title";
import { betweenNumeric, required } from "@/utils/rules";
import { computed, ref } from "vue";
import { usePersistPayoutMethod } from "@/composables/persist-payout-method";
import { useTransientPayoutMethod } from "@/composables/transient-payout-method";
import CoinbaseLogo from "@/assets/img/payout-methods/coinbase.svg?inline";
import { useDisburse } from "@/composables/disburse";
import { useAppStore } from "@/stores/app";

export default {
  name: "CoinBaseForm",

  components: {
    SecureTextField,
    NameTextField,
    AppTextField,
    PayoutMethodsConfirmCancelDialog,
    TermsAndConditionsCheckbox,
    CoinbaseLogo,
  },

  setup() {
    const authStore = useAuthStore();
    const payoutMethodStore = usePayoutMethodStore();
    const payoutStore = usePayoutStore();
    const appStore = useAppStore();
    const { user, isGuest } = storeToRefs(authStore);
    const { selected: selectedPayout } = storeToRefs(payoutStore);
    const { selected: selectedPayoutMethod } = storeToRefs(payoutMethodStore);
    const options = { mask: "#########" };
    const { persisted: savedPayoutMethods } = storeToRefs(payoutMethodStore);

    const title = authStore.isGuest
      ? i18n.global.t("CoinBaseForm.guestTitle")
      : i18n.global.t("CoinBaseForm.bankAccount");
    useTitle(title);

    const { store } = useTransientPayoutMethod();
    const {
      disburse,
      disburseLoading,
      error: disburseError,
      disabled: transferDisabled,
    } = useDisburse();
    const { persistLoading, persist, isEditing } =
      usePersistPayoutMethod("CoinBaseForm");
    const form = ref();
    const firstName = ref();
    const routingNumberLoading = ref();
    const lastName = ref();
    const accountNumber = ref("");
    const confirmAccountNumber = ref("");
    const acceptTermsAndConditions = ref(false);
    const isPrimaryMethod = ref(false);
    const isFormValid = ref(false);
    const showConfirmCancel = ref(false);
    const savePayoutAccount = ref(false);
    const routingNumber = ref({
      value: "",
      invalid: false,
      hasValidated: false,
      errorMessages: "",
    });

    const onSubmit = computed(() => (payoutStore.selected ? store : persist));
    const accountNumbersMatch = computed(() => {
      return (
        isEqual(accountNumber.value, confirmAccountNumber.value) ||
        i18n.global.t("CoinBaseForm.confirmAccountNumbers")
      );
    });

    const disbursementFlowActive = computed(() => {
      return !!selectedPayout.value;
    });

    const transient = computed(() => {
      return isGuest.value || disbursementFlowActive.value;
    });

    const disableForm = computed(() => {
      return transferDisabled.value;
    });

    const errorSavedPayoutMethod = computed(function () {
      const messageArray = [];
      savedPayoutMethods.value.length > 4
        ? messageArray.push(
            i18n.global.t("PayoutsAvailable.savePayoutAccountError")
          )
        : [];
      return messageArray;
    });

    const prePopulateForm = () => {
      if (user.value) {
        firstName.value = user.value.firstName;
        lastName.value = user.value.lastName;
      }
      fetchCoinBaseDetails();
    };

    async function fetchCoinBaseDetails() {
      routingNumberLoading.value = true;
      try {
        const routingNumbers = await payoutMethodStore.fetchRoutingNumber();
        routingNumber.value.value = routingNumbers[0].routingNumber;
      } catch (error) {
        appStore.showError(i18n.global.t("CoinBaseForm.networkError"));
      } finally {
        routingNumberLoading.value = false;
      }
    }

    const apiSchema = () => ({
      recipientProfileInfo: {
        recipientId: authStore.user.recipientId,
        firstName: firstName.value,
        lastName: lastName.value,
      },
      source: "COINBASE",
      coinbase: {
        accountNumber: accountNumber.value,
        routingNumber: routingNumber.value.value,
        default: isPrimaryMethod.value,
        firstName: firstName.value,
        lastName: lastName.value,
      },
    });

    const validateForm = async () => {
      const valid = await form.value.validate();
      if (!valid.valid) return false;

      return true;
    };

    const submit = async () => {
      const valid = await validateForm();
      if (!valid) return;
      await onSubmit.value(apiSchema());
      if (transient.value) {
        payoutStore.setSavePayoutAccount(
          savePayoutAccount.value ? "true" : "false"
        );
        disburse();
      }
    };

    return {
      authStore,
      payoutMethodStore,
      payoutStore,
      isEditing,
      persistLoading,
      onSubmit,
      required,
      betweenNumeric,
      prePopulateForm,
      firstName,
      lastName,
      submit,
      disburse,
      disburseLoading,
      disburseError,
      transferDisabled,
      disableForm,
      accountNumber,
      confirmAccountNumber,
      apiSchema,
      accountNumbersMatch,
      disbursementFlowActive,
      transient,
      mdiAlert,
      mdiCheck,
      mdiInformation,
      acceptTermsAndConditions,
      isFormValid,
      showConfirmCancel,
      routingNumber,
      isPrimaryMethod,
      user,
      isGuest,
      selectedPayout,
      selectedPayoutMethod,
      fetchCoinBaseDetails,
      routingNumberLoading,
      appStore,
      options,
      savePayoutAccount,
      savedPayoutMethods,
      errorSavedPayoutMethod,
      validateForm,
      form,
    };
  },

  mounted() {
    this.prePopulateForm();
  },
};
</script>
<style scoped>
.blank-check-tool-tip {
  border: 1px solid #ffffff;
  border-top: none;
  font-size: 0.5rem;
}

.routing-account-width {
  width: 85px;
}
</style>
