<template>
  <v-menu
    v-model="open"
    offset-y
    tile
    :bottom="smAndUp"
    :nudge-bottom="smAndUp ? 10 : 0"
    :top="xs"
    :nudge-top="xs ? 15 : 0"
    left
    :attach="xs ? '#mobile-bottom-nav' : '#app'"
  >
    <template #activator="{ props }">
      <slot :props="props"></slot>
    </template>

    <v-list>
      <v-list-item :to="{ name: 'profile' }">
        <template #prepend class="mr-2">
          <svg-icon color="primary" type="mdi" :path="mdiCogOutline"></svg-icon>
        </template>
        <v-list-item-title class="mr-2">{{
          $t("TheUserMenu.profileSettings")
        }}</v-list-item-title>
      </v-list-item>

      <v-list-item data-test="sign-out-btn" @click="signOut">
        <template #prepend class="mr-2">
          <svg-icon color="primary" type="mdi" :path="mdiLogout"></svg-icon>
        </template>
        <v-list-item-title class="mr-2">{{
          $t("TheUserMenu.logout")
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiCogOutline, mdiLogout } from "@mdi/js";
import { useAuthStore } from "@/stores/auth";
import { useDialogPanelStore } from "@/stores/dialog-panel";
import { useDisplay } from "vuetify";

export default {
  name: "TheUserMenu",

  setup() {
    const authStore = useAuthStore();
    const dialogPanelStore = useDialogPanelStore();
    const { smAndUp, xs } = useDisplay();

    return { authStore, dialogPanelStore, smAndUp, xs };
  },

  data() {
    return {
      mdiCogOutline,
      mdiLogout,
      open: false,
    };
  },
  watch: {
    open(newVal) {
      this.dialogPanelStore.setMenuExandFlag(newVal);
    },
  },

  methods: {
    signOut() {
      this.authStore.signOut();
      this.$router.push({ name: "sign-in" }).then(() => {
        this.authStore.$reset();
      });
    },
  },
};
</script>
