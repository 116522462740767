<template>
  <div>
    <portal to="guestPayoutStatus">
      <span data-test="guest-payout-status">
        <guest-payout-status
          v-if="payout"
          :payout="payout"
          class="d-flex justify-center"
          :class="xs && 'px-2'"
        />
      </span>
    </portal>

    <custom-payout-detail-fields
      class="mb-5"
      :custom-fields="payout.customFields"
      :merchant="payout.merchant.groupName"
    />

    <v-divider v-if="smAndDown" class="mb-5" />

    <payout-details-actions class="mb-2" />
  </div>
</template>

<script>
import { mapState } from "pinia";
import PayoutDetailsActions from "@/components/payouts/PayoutDetailsActions";
import GuestPayoutStatus from "@/components/guest/GuestPayoutStatus";
import { usePayoutStore } from "@/stores/payout";
import CustomPayoutDetailFields from "@/components/payouts/CustomPayoutDetailFields";
import { i18n } from "@/plugins/i18n";
import { useTitle } from "@/composables/title";
import { useDisplay } from "vuetify";

export default {
  name: "GuestPayoutDetail",

  components: {
    CustomPayoutDetailFields,
    GuestPayoutStatus,
    PayoutDetailsActions,
  },

  setup() {
    useTitle(i18n.global.t("GuestPayoutDetail.title"));
    const { xs, smAndDown } = useDisplay();

    return { xs, smAndDown };
  },

  computed: {
    ...mapState(usePayoutStore, { payout: "selected" }),
  },
};
</script>
