<template>
  <v-checkbox
    v-model="checked"
    :rules="[required()]"
    v-bind="$attrs"
    aria-required="true"
  >
    <template #label>
      <div @click.stop>
        {{ $t("TermsAndConditionsCheckbox.acceptTerms") }}

        <termsOfUse
          class="text-decoration-underline font-weight-bold"
          data-test="link-to-terms-conditions"
          :payout-method-type="payoutMethodType"
        />
      </div>
    </template>
  </v-checkbox>
</template>

<script>
import { required } from "@/utils/rules";
import termsOfUse from "@/components/legal/termsOfUse";
export default {
  name: "TermsAndConditionsCheckbox",

  components: { termsOfUse },

  model: {
    event: "change",
  },

  props: {
    value: { type: Boolean, default: false },
    payoutMethodType: {
      type: String,
      default: "",
    },
  },

  emits: ["change"],

  setup() {
    return { required };
  },

  computed: {
    checked: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("change", val);
      },
    },
  },
};
</script>
