<script>
import AppTextField from "@/components/forms/AppTextField";
import { useVModel } from "@vueuse/core";
import { required, minLength } from "@/utils/rules";
import { i18n } from "@/plugins/i18n";
import { computed } from "vue";

export default {
  name: "CityField",

  components: { AppTextField },

  inheritAttrs: false,

  props: {
    value: { type: String, default: null },
    label: { type: [String, Object], default: "" },
    placeholder: { type: String, default: "" },
    rules: { type: Array, default: () => [] },
  },

  setup(props, { emit }) {
    const maxCityLength = 50;
    const baseRules = [
      required(i18n.global.t("CityField.required")),
      minLength(3, i18n.global.t("CityField.minLength")),
    ];
    const combinedRules = computed(() => {
      return [...props.rules, ...baseRules];
    });
    const modelValue = useVModel(props, "value", emit, { eventName: "input" });
    return { modelValue, baseRules, maxCityLength, combinedRules };
  },
};
</script>

<template>
  <AppTextField
    v-model="modelValue"
    :label="label"
    :placeholder="placeholder"
    :rules="combinedRules"
    v-bind="$attrs"
    :maxlength="maxCityLength"
    data-test="city"
  />
</template>
