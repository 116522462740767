<template>
  <confirm-dialog
    v-model="showSessionTimeoutDialog"
    :title="$t('ActivityTracker.sessionTimeoutDialog.title')"
    :show-cancel-button="false"
    :confirm-button-text="
      $t('ActivityTracker.sessionTimeoutDialog.confirmButtonText')
    "
    @input:confirm="sessionContinue"
  >
    <i18n-t
      id="opt-in-legal-guest-text"
      keypath="ActivityTracker.sessionTimeoutDialog.content"
      tag="span"
      data-test="session-timeout-text"
    >
      {{ elapsedTime }}
    </i18n-t>
  </confirm-dialog>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import { differenceInSeconds, addSeconds, isPast } from "date-fns";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "ActivityTracker",

  components: {
    ConfirmDialog,
  },

  setup() {
    const authStore = useAuthStore();

    return { authStore };
  },

  data() {
    return {
      activityTimeout: null,
      backendActiveTimeout: null,
      elapsedTime: "",
      showSessionTimeoutDialog: false,
      showSessionTimeoutDialogTimer: null,
      timer: null,
    };
  },

  computed: {
    user() {
      return this.authStore.user;
    },

    expiration() {
      if (!this.showSessionTimeoutDialog) {
        return;
      }

      const now = new Date();
      const defaultTTL = 120;

      return addSeconds(now, defaultTTL);
    },
  },

  created() {
    this.keepBackendActive();
    this.setActivityTimeout();
    window.addEventListener("mousemove", this.setActivityTimeout);
    window.addEventListener("scroll", this.setActivityTimeout);
    window.addEventListener("keydown", this.setActivityTimeout);
  },

  beforeUnmount() {
    window.removeEventListener("mousemove", this.setActivityTimeout);
    window.removeEventListener("scroll", this.setActivityTimeout);
    window.removeEventListener("keydown", this.setActivityTimeout);
    clearTimeout(this.activityTimeout);
    clearTimeout(this.backendActiveTimeout);
  },

  methods: {
    setActivityTimeout() {
      if (this.user && !this.showSessionTimeoutDialog) {
        clearTimeout(this.activityTimeout);
        clearTimeout(this.showSessionTimeoutDialogTimer);

        this.activityTimeout = setTimeout(() => {
          this.endSession();
        }, 15 * 60 * 1000);

        this.showSessionTimeoutDialogTimer = setTimeout(() => {
          this.showSessionTimeoutDialog = true;
          this.startTimer();
        }, 13 * 60 * 1000);
      }
    },

    endSession() {
      //Check for user again in case user has logged out manually
      if (this.user) {
        this.authStore.signOut();
        this.$router.push({
          name: "session-ended",
          query: { guest: this.user.guest.toString() },
        });
      }
    },

    sessionContinue() {
      clearTimeout(this.timer);
      this.showSessionTimeoutDialog = false;
      this.setActivityTimeout();
    },

    keepBackendActive() {
      this.backendActiveTimeout = setTimeout(() => {
        const isGuestuser = this.authStore.isGuest;
        if (this.user) {
          this.authStore.fetchUser().then(() => {
            if (isGuestuser) {
              this.authStore.setIsGuestFlow();
            }
            this.keepBackendActive();
          });
        }
      }, 5 * 60 * 1000);
    },

    startTimer() {
      if (isPast(this.expiration)) {
        this.showSessionTimeoutDialog = false;
        return;
      }

      const self = this;
      const now = new Date();
      const diff = differenceInSeconds(this.expiration, now);
      const minutes = Math.floor(diff / 60);

      let seconds = diff - minutes * 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      this.elapsedTime = `${minutes}:${seconds}`;
      this.timer = setTimeout(() => {
        self.startTimer();
      }, 1000);
    },
  },
};
</script>
