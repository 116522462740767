<template>
  <v-select
    v-model="modelValue"
    :items="items"
    :label="$t('AchClassificationSelect.accountClassification')"
    item-title="text"
    item-value="value"
    :rules="[required()]"
    data-test="classification-select"
    aria-required="true"
  />
</template>

<script>
import { required } from "@/utils/rules";
import { useVModel } from "@vueuse/core";
import { i18n } from "@/plugins/i18n";

export default {
  name: "AchClassificationSelect",

  components: {},

  props: {
    value: { type: String, default: null },
  },

  setup(props, { emit }) {
    const modelValue = useVModel(props, "value", emit, { eventName: "input" });

    return {
      modelValue,
      required,
    };
  },

  data() {
    return {
      items: [
        {
          text: i18n.global.t("AchClassificationSelect.personal"),
          value: "PERSONAL",
        },
        {
          text: i18n.global.t("AchClassificationSelect.business"),
          value: "BUSINESS",
        },
      ],
    };
  },
};
</script>
