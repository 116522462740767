<template>
  <confirm-dialog
    v-model="visible"
    :title="'eCheck Privacy Notice'"
    :show-cancel-button="true"
    :cancel-button-fn="closeFn"
    :confirm-button-text="'Continue'"
    @input:confirm="visible = false"
  >
    <i18n-t keypath="ECheckForm.popupMsg" tag="span">
      <a
        class="fiserv-dark-blue--text text-decoration-none"
        target="_blank"
        href="https://urldefense.com/v3/__https:/checkbook.io/resource/privacy/*:*:text=Checkbook*20will*20not*20rent*20or,co*2Dlocated*20with*20hosting*20providers.__;I34lJSUlJSUlJQ!!P9vvK-4S!gQ6h8Xll5YJmw-3XitlHHD82lp7TdHJW4iRu1twqW70FV66ouDMPs24S4LET8GDRaJ9Mxv0JYPLLQ5ZgQbPEA7wx0Q$"
        >{{ $t("ECheckForm.popupHere") }}</a
      >
    </i18n-t>
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "ECheckConfirm",
  components: {
    ConfirmDialog,
  },
  props: {
    show: Boolean,
    closeFn: Function,
  },
  data() {
    return {
      visible: this.show,
    };
  },
};
</script>
