<template>
  <div class="d-flex flex-column">
    <e-check-confirm
      :show="showDialog"
      :close-fn="closeDialog"
    ></e-check-confirm>

    <div class="pt-4 font-weight-bold" data-test="eCheck-info-section">
      {{ $t("ECheckForm.info") }}
    </div>

    <custom-payout-detail-fields
      :custom-fields="payout.customFields"
      :merchant="payout.merchant.groupName"
      data-test="custom-payout-detail-section"
      class="pt-5"
    />

    <v-divider class="mt-5"></v-divider>

    <div
      v-if="multiRecipient"
      class="pt-4 font-weight-bold"
      data-test="eCheck-multi-recipient-info-section"
    >
      {{ $t("ECheckForm.multiRecipientInfo") }}
    </div>

    <v-form ref="form" v-model="isFormValid">
      <div class="d-flex pt-5 pt-sm-0 px-3 px-sm-4">
        <terms-and-conditions-checkbox
          v-model="acceptTermsAndConditions"
          payout-method-type="ECHECK"
          data-test="add-e-check-account-terms-and-conditions"
          class="payoutTermsCondition"
        />
      </div>

      <div class="d-flex justify-center align-center py -10">
        <v-btn
          depressed
          tile
          :disabled="disburseLoading"
          :loading="disburseLoading"
          color="primary"
          class="mx-2 custom_min_width_100"
          data-test="add-e-check-submit"
          @click="transfer"
          >{{ $t("ECheckForm.submit") }}</v-btn
        >
        <v-btn
          variant="outlined"
          tile
          class="mx-2 custom_min_width_100"
          data-test="add-e-check-cancel"
          @click="showConfirmCancel = true"
        >
          {{ $t("ECheckForm.cancel") }}
        </v-btn>
      </div>
    </v-form>

    <payout-methods-confirm-cancel-dialog v-model="showConfirmCancel" />
  </div>
</template>

<script>
import CustomPayoutDetailFields from "@/components/payouts/CustomPayoutDetailFields";
import PayoutMethodsConfirmCancelDialog from "@/components/payout-methods/PayoutMethodsConfirmCancelDialog";
import TermsAndConditionsCheckbox from "@/components/forms/TermsAndConditionsCheckbox";
import { useAuthStore } from "@/stores/auth";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import { useDynamicContent } from "@/composables/dynamic-content";
import { isMultiRecipient } from "@/utils/payout";
import { useDisburse } from "@/composables/disburse";
import { usePayoutStore } from "@/stores/payout";
import { useBack } from "@/composables/back";
import NewPayoutMethods from "@/components/payout-methods/NewPayoutMethods";
import ECheckConfirm from "./ECheckConfirm.vue";

export default {
  name: "ECheckForm",

  components: {
    CustomPayoutDetailFields,
    PayoutMethodsConfirmCancelDialog,
    TermsAndConditionsCheckbox,
    ECheckConfirm,
  },

  setup() {
    const authStore = useAuthStore();
    const payoutMethodStore = usePayoutMethodStore();
    const payoutStore = usePayoutStore();

    useTitle(i18n.global.t("ECheckForm.title"));

    const { setContent } = useDynamicContent();
    useBack(() => setContent(NewPayoutMethods));
    const { disburse, disburseLoading } = useDisburse();

    return {
      disburse,
      disburseLoading,
      authStore,
      payoutMethodStore,
      payoutStore,
      setContent,
    };
  },

  data() {
    return {
      isFormValid: false,
      acceptTermsAndConditions: false,
      showConfirmCancel: false,
      showDialog: true,
    };
  },

  computed: {
    payout() {
      return this.payoutStore.selected;
    },

    user() {
      return this.authStore.user;
    },

    name() {
      return this.user.recipientType.toLowerCase() === "company"
        ? this.user.doingBusinessAs
        : `${this.user.firstName} ${this.user.lastName}`;
    },

    multiRecipient() {
      return isMultiRecipient(this.payout);
    },
  },

  methods: {
    apiSchema() {
      return {
        recipientProfileInfo: {
          recipientId: this.user.recipientId,
        },
        source: "ECHECK",
        eCheck: {
          email: { ...this.user.emailAddress },
          name: this.name,
        },
      };
    },

    async transfer() {
      const valid = await this.$refs.form.validate();
      if (!valid.valid) return;

      this.payoutMethodStore.selected = this.apiSchema();
      this.disburse();
    },

    closeDialog() {
      this.setContent(NewPayoutMethods);
    },
  },
};
</script>
