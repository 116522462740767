<template>
  <div v-if="payout" class="d-flex flex-column" data-test="payout-details">
    <portal to="userPayoutStatus">
      <guest-payout-status
        v-if="payout"
        :payout="payout"
        class="d-flex justify-center"
        :class="xs && 'px-2'"
      />
    </portal>
    <payout-summary :payout="payout" />

    <custom-payout-detail-fields
      :custom-fields="payout.customFields"
      :merchant="payout.merchant.groupName"
      class="pt-5"
    />

    <multi-recipient-status-other
      v-if="multiRecipient"
      :payout="payout"
      class="my-5"
    />

    <payout-details-actions class="my-5" />
  </div>
</template>

<script>
import { mapState } from "pinia";
import MultiRecipientStatusOther from "@/components/multi-recipient/MultiRecipientStatusOther";
import CustomPayoutDetailFields from "./CustomPayoutDetailFields";
import { usePayoutStore } from "@/stores/payout";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import { isMultiRecipient } from "@/utils/payout";
import PayoutSummary from "@/components/payouts/PayoutSummary";
import GuestPayoutStatus from "@/components/guest/GuestPayoutStatus";
import { useDisplay } from "vuetify";

import PayoutDetailsActions from "@/components/payouts/PayoutDetailsActions";

export default {
  name: "PayoutDetails",

  components: {
    PayoutSummary,
    CustomPayoutDetailFields,
    PayoutDetailsActions,
    MultiRecipientStatusOther,
    GuestPayoutStatus,
  },

  setup() {
    useTitle(i18n.global.t("PayoutDetails.title"));
    const { xs } = useDisplay();
    return { xs };
  },

  computed: {
    ...mapState(usePayoutStore, { payout: "selected" }),

    multiRecipient() {
      return isMultiRecipient(this.payout);
    },

    selected() {
      return this.payout;
    },
  },

  beforeCreate() {
    this.$options.components.PayoutSummary =
      require("@/components/payouts/PayoutSummary").default;
  },
};
</script>
