import { defineStore } from "pinia";

const state = () => {
  return {
    uiSortManagement: {
      filteredHistory: [],
      amountFlag: {
        mdiFilterVariantStatus: false,
      },
      headerFlagList: {
        amountSortDescendingStatus: true,
        amountSortClicked: false,
        merchantSortAssendingStatus: true,
        mdiSortActiveStatus: false,
        merchantSortClicked: false,
      },
    },
  };
};

const actions = {
  resetuiSortManagement() {
    this.$patch({
      uiSortManagement: {
        filteredHistory: [],
        amountFlag: {
          mdiFilterVariantStatus: false,
          minAmount: 0,
          maxAmount: 0,
          amountFilterActive: false,
        },
        headerFlagList: {
          merchantSortAssendingStatus: true,
          amountSortDescendingStatus: true,
          mdiSortActiveStatus: false,
          amountSortClicked: false,
          merchantSortClicked: false,
        },
      },
    });
  },
};

export const usePayoutSortfilterStore = defineStore("payoutSortfilter", {
  state,
  actions,
});
