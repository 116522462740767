import { defineStore } from "pinia";
import { useAuthStore } from "@/stores/auth";

const state = () => {
  return {
    visible: false,
    content: null,
    bannerviewDialogArray: [],
    isBannerDismissed: false,
    isBannerConfigured: false,
    bannerDissmissedInxCollection: [], //Array which will store information screenwise whether perticular screen
    // user has clicked  dismiss or not
    isMenuExpanded: false,
  };
};
const getters = {
  getBannerDismissedFlag() {
    return this.isBannerDismissed;
  },

  getMenuExpandStatus() {
    return this.isMenuExpanded;
  },

  isglobalBannerConfigured() {
    return this.isBannerConfigured;
  },
};
const actions = {
  show(content) {
    this.$patch({ content, visible: true });
  },

  hide() {
    this.$reset();
    this.visible = false;
    this.content = null;
  },
  setBannerDismiss(param) {
    this.isBannerDismissed = param;
  },
  setBannerConfig(param) {
    this.isBannerConfigured = param;
  },

  setMenuExandFlag(param) {
    this.bannerDissmissedInxCollection[1] == 1 && param
      ? (this.isMenuExpanded = false)
      : (this.isMenuExpanded = param);
  },

  // action to set drawer payouts view
  setRegisterUserPayouts() {
    const authStore = useAuthStore();
    let dialogPanelViews = [];
    let flatArray = [];
    const data = authStore.getNotificationData;
    data.notifications.forEach((ok) => {
      dialogPanelViews.push(ok.views);

      flatArray = [].concat(...dialogPanelViews);
    });
    this.bannerviewDialogArray = flatArray;
    if (this.bannerDissmissedInxCollection.length <= 0) {
      for (let i = 0; i < this.bannerviewDialogArray.length; i++) {
        this.bannerDissmissedInxCollection.push(0);
      }
    }
  },

  setBannerDissmissedIndex(viewName) {
    const arrIndex = this.bannerviewDialogArray.indexOf(viewName);
    this.bannerDissmissedInxCollection[arrIndex] = 1;
  },
  validateIsscreendismissed(viewName) {
    const arrIndex = this.bannerviewDialogArray.indexOf(viewName);
    const dismissFlag =
      this.bannerDissmissedInxCollection[arrIndex] === 1 ? true : false;

    return dismissFlag;
  },
};

export const useDialogPanelStore = defineStore("dialogPanel", {
  state,
  getters,
  actions,
});
