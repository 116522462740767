<template>
  <div v-if="status !== 'not-actionable'">
    <div
      v-if="showOtherUserClaimedDisclaimer"
      class="mb-5"
      data-test="multi-payout-to-claimed"
    >
      {{ $t("PayoutDetailsActions.multiRecipientOtherUserClaimed") }}
    </div>
    <div class="d-flex justify-center align-center">
      <v-btn
        tile
        depressed
        color="primary"
        min-width="125"
        data-test="accept-button"
        class="mr-2"
        :loading="loading"
        @click="status.action"
      >
        {{ status.btnTxt }}
      </v-btn>

      <v-btn
        tile
        variant="outlined"
        color="primary"
        min-width="125"
        data-test="reject-button"
        class="ml-2"
        @click="reject"
      >
        {{ $t("PayoutDetailsActions.reject") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { usePayoutStore } from "@/stores/payout";
import { useAuthStore } from "@/stores/auth";
import { useAppStore } from "@/stores/app";
import get from "lodash/get";
import TimeoutError from "@/components/payouts/available/disbursements/TimeoutError";
import { useDynamicContent } from "@/composables/dynamic-content";
import { resolveErrorMsg } from "@/utils/error-handler";
import {
  getOtherRecipient,
  getOtherRecipientPaymentStatus,
  getRecipient,
  isApproved,
  isDeclined,
  isMultiRecipient,
  isPending,
} from "@/utils/payout";
import { i18n } from "@/plugins/i18n";

import AcceptPayout from "@/components/payouts/available/disbursements/AcceptPayout";
import RejectPayout from "@/components/payouts/RejectPayout";
import ConfirmDisbursePayout from "@/components/payouts/available/disbursements/ConfirmDisbursePayout";
import GuestTransferSuccess from "@/components/guest/GuestTransferSuccess";
import NewPayoutMethods from "@/components/payout-methods/NewPayoutMethods";

export default {
  name: "PayoutDetailsActions",

  setup() {
    const payoutStore = usePayoutStore();
    const authStore = useAuthStore();
    const appStore = useAppStore();

    const { setContent } = useDynamicContent();

    return {
      payoutStore,
      authStore,
      appStore,
      setContent,
    };
  },

  data() {
    return {
      payoutStatuses: {
        acceptable: {
          btnTxt: i18n.global.t("PayoutDetailsActions.accept"),
          action: this.accept,
        },
        approvable: {
          btnTxt: i18n.global.t("PayoutDetailsActions.approve"),
          action: this.approve,
        },
      },

      loading: false,
    };
  },

  computed: {
    multiRecipient() {
      return isMultiRecipient(this.payout);
    },

    isUser() {
      return this.authStore.isUser;
    },

    payout() {
      return this.payoutStore.selected;
    },

    isMfaEnabled() {
      return this.authStore.session.mfaEnabled;
    },

    status() {
      const declined = isDeclined(this.payout);
      const pending = isPending(this.payout);
      const approved = isApproved(this.payout);

      if (!this.multiRecipient && (pending || declined || approved)) {
        return this.payoutStatuses.acceptable;
      }

      if (!this.multiRecipient && declined) {
        return this.payoutStatuses.actionRequired;
      }

      if (this.multiRecipient) {
        if (pending) return this.payoutStatuses.approvable;
        if (declined) return this.payoutStatuses.acceptable;
      }

      if (
        approved &&
        (this.otherRecipientPaymentStatus === "DA" ||
          this.otherRecipientPaymentStatus === "DP")
      ) {
        return this.payoutStatuses.acceptable;
      }

      return "not-actionable";
    },

    acceptComponent() {
      return this.isUser ? AcceptPayout : NewPayoutMethods;
    },

    confirmComponent() {
      return this.isUser ? ConfirmDisbursePayout : GuestTransferSuccess;
    },

    isECheck() {
      return (
        this.multiRecipient &&
        (this.recipient.source === "ECHECK" ||
          getOtherRecipient(this.payout).source === "ECHECK")
      );
    },

    recipient() {
      return getRecipient(this.payout);
    },

    otherRecipientPaymentStatus() {
      return getOtherRecipientPaymentStatus(this.payout);
    },

    showOtherUserClaimedDisclaimer() {
      return this.multiRecipient && this.otherRecipientPaymentStatus === "DS";
    },
  },

  methods: {
    accept() {
      this.setContent(this.acceptComponent);
    },

    reject() {
      if (this.isUser) {
        this.setContent(RejectPayout);
      } else {
        this.$router.push({ name: "guest-reject-payout" });
      }
    },

    async approve() {
      this.loading = true;

      try {
        await this.payoutStore.approve({
          transactionId: this.payout.transactionId,
          paymentId: this.recipient.payments.paymentId,
        });

        // if other recipient has claimed, disbursement is now complete
        if (
          this.otherRecipientPaymentStatus === "DS" ||
          this.otherRecipientPaymentStatus === "DI" ||
          this.otherRecipientPaymentStatus === "IP" ||
          this.otherRecipientPaymentStatus === "IR" ||
          this.otherRecipientPaymentStatus === "PR"
        ) {
          if (this.isUser) {
            this.payoutStore.fetchAvailable();
            this.payoutStore.fetchHistory();
            this.payoutStore.fetchSummary();
          }
          this.setContent({
            component: this.confirmComponent,
            attrs: this.isUser
              ? { checkImageUrl: this.recipient.checkImageUrl }
              : {
                  checkImageUrl: this.recipient.checkImageUrl,
                  /*
                    NOTE: Fix for DSDDPBS-1739ß
                    This fix for mfaEnabled isn't a good one, there needs to be an effort made to find out
                    where the origin of the mfaEnabled boolean is being set as a string that says true or false.
                    However, there isn't enough time to sit and check every case, so we will have to find a sprint to do a proper fix.
                   */
                  mfaEnabled:
                    typeof this.isMfaEnabled == "string"
                      ? this.isMfaEnabled === "true"
                      : this.isMfaEnabled,
                },
          });
          return;
        }

        // Need to ensure the payout is updated in the Available list
        // to prevent selection of a payout with stale data
        if (this.isUser) {
          this.payoutStore.fetchAvailable();
        }

        this.setContent(this.acceptComponent);
      } catch (err) {
        const code = get(err, "response.data.code");
        const devMsg = get(err, "response.data.developerInfo.developerMessage");

        if (devMsg === "Bluecheck API") {
          if (this.isUser) {
            this.payoutStore.fetchAvailable();
            this.payoutStore.fetchHistory();
            this.payoutStore.fetchSummary();

            const bcMsg = resolveErrorMsg(err, `AchForm.blueCheckError`);

            this.appStore.showError(bcMsg);
          }
        }

        if (this.isECheck && code === "500000") {
          if (this.isUser) {
            this.payoutStore.fetchAvailable();
            this.payoutStore.fetchHistory();
            this.payoutStore.fetchSummary();
          }

          this.setContent({
            component: TimeoutError,
            attrs: { msgContent: i18n.global.t("ECheckForm.payoutErrorMsg") },
          });
        } else {
          const msg = resolveErrorMsg(err, "PayoutDetailsActions.default");
          this.appStore.showError(msg);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
