<template>
  <a
    :href="privacyPolicyUrl"
    class="text-decoration-underline"
    data-test="link-to-privacy-policy"
    target="_blank"
  >
    {{
      bankcode === "002"
        ? $t("TheFooter.onlinePrivacyNotice")
        : $t("TheFooter.privacyNotice")
    }}
  </a>
</template>

<script>
export default {
  name: "PrivacyPolicy",

  props: {
    bankcode: { type: String, default: "001" },
  },

  computed: {
    privacyPolicyUrl() {
      return (
        process.env.VUE_APP_DDP_ASSETS_URL +
        "/docs/Recipient_Portal_Privacy_Notice.pdf"
      );
    },
  },
};
</script>
