<template>
  <app-text-field
    v-model="vmodelValue"
    v-maska:[options]
    :placeholder="$t('dateFormat.short').toUpperCase()"
    :label="label"
    :rules="combinedRules"
    v-bind="$attrs"
    autocomplete="bday"
    @input="maybeChanged"
  />
</template>

<script>
import AppTextField from "@/components/forms/AppTextField";
import { isValid, parse } from "date-fns";
import { i18n } from "@/plugins/i18n";
import { computed } from "vue";

export default {
  name: "DateTextField",

  components: { AppTextField },

  inheritAttrs: false,

  props: {
    modelValue: { type: String, default: null },
    label: { type: [String, Object], default: "" },
    rules: { type: Array, default: () => [] },
  },

  emits: ["update:modelValue", "change"],

  setup(props, { emit }) {
    const vmodelValue = computed({
      get() {
        return props.modelValue;
      },
      set(v) {
        emit("update:modelValue", v);
      },
    });

    const options = { mask: "##/##/####" };
    return { vmodelValue, options };
  },

  computed: {
    mask() {
      return String(i18n.global.t("dateFormat.short")).replace(
        /[a-zA-Z]/g,
        "#"
      );
    },

    combinedRules() {
      return [...this.rules, this.isValidDate, this.isValidFormat];
    },

    isValidDate() {
      const date = parse(
        this.modelValue,
        String(i18n.global.t("dateFormat.short")),
        new Date()
      );

      return isValid(date) || i18n.global.t("DateTextField.dateFormat");
    },

    isValidFormat() {
      const pattern = /^(\d{2})(\/|'')?(\d{2})(\/|'')?(\d{4})$/;
      return (
        pattern.test(this.modelValue) ||
        i18n.global.t("DateTextField.dateFormat")
      );
    },

    isValid() {
      return this.isValidDate && this.isValidFormat;
    },
  },

  methods: {
    maybeChanged() {
      if (this.isValid === true) this.$emit("change", this.modelValue);
    },
  },
};
</script>
