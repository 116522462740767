export default {
  statefarm: {
    color: "#ec0c21",
    customFieldExclusions: ["cc", "bcc", "covrage_paid"],
  },
  newmexicomutual: {
    color: "#043673",
  },
  verisk_pt1: {
    color: "#00358E",
  },
  verisk_pt2: {
    color: "#00358E",
  },
  guinivere1: {
    color: "#233746",
    customFieldExclusions: ["cc", "bcc", "covrage_paid"],
  },
  guinivere2: {
    color: "#233746",
    customFieldExclusions: ["cc", "bcc", "covrage_paid"],
  },
  fortunecoins: {
    color: "#000000",
  },
  indianafarmers: {
    color: "#414042",
    customFieldExclusions: ["cc", "bcc"],
  },
  aon: {
    color: "#EB0017",
  },
  sodexo: {
    color: "#231F20",
    hideCreateAccount: true,
    customFieldExclusions: ["cc", "bcc"],
  },
  sodexo_treasury: {
    color: "#283897",
    hideCreateAccount: true,
    customFieldExclusions: ["cc", "bcc"],
  },
  walmart: {
    color: "#0474c4",
  },
  us_forestry: {
    color: "#ffffff",
  },
  sodexoedrf: {
    color: "#000000",
    hideCreateAccount: true,
    customFieldExclusions: ["cc", "bcc"],
  },
  sodexoinreach: {
    color: "#DC4405",
    hideCreateAccount: true,
    customFieldExclusions: ["cc", "bcc"],
  },
};
