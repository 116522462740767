<template>
  <fieldset style="border: none">
    <v-row no-gutters>
      <v-col class="pr-md-4" cols="12" md="6">
        <StreetAddressField
          v-model="form.address1"
          :disabled="disabled"
          :max-address-length="40"
          :label="$t('StreetAddressForm.address1')"
          :rules="[addressWhiteList(form.address1)]"
        />
      </v-col>
      <v-col class="pl-md-4" cols="12" md="6">
        <AppTextField
          v-model="form.address2"
          :disabled="disabled"
          :rules="[addressWhiteList(form.address2)]"
          maxlength="40"
          :label="$t('StreetAddressForm.address2')"
          data-test="address2"
        />
      </v-col>
    </v-row>
    <v-row class="pb-4" no-gutters>
      <v-col class="pr-md-4" cols="12" md="6">
        <CityField
          v-model="form.city"
          :disabled="disabled"
          :rules="[cityWhiteList]"
          :label="$t('StreetAddressForm.city')"
        />
      </v-col>
      <v-col cols="12" md="4" class="px-md-4 mn-state-alignment"
        ><!-- -->
        <v-select
          v-model="form.state"
          :disabled="disabled"
          :items="states"
          item-title="name"
          item-value="abbreviation"
          :rules="[required()]"
          :label="$t('StreetAddressForm.state')"
          data-test="state"
        />
      </v-col>
      <v-col class="pl-md-2" cols="12" md="2">
        <ZipCodeField
          v-model="form.zipcode"
          :disabled="disabled"
          :label="$t('StreetAddressForm.zipcode')"
        />
      </v-col>
    </v-row>
  </fieldset>
</template>

<script>
import StreetAddressField from "@/components/forms/StreetAddressField";
import AppTextField from "@/components/forms/AppTextField";
import CityField from "@/components/forms/CityField";
import ZipCodeField from "@/components/forms/ZipCodeField";
import states from "@/utils/states";
import { required } from "@/utils/rules";
import { i18n } from "@/plugins/i18n";
export default {
  name: "StreetAddressForm",

  components: {
    ZipCodeField,
    CityField,
    AppTextField,
    StreetAddressField,
  },
  props: {
    form: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
  },

  setup() {
    return { states, required };
  },

  methods: {
    addressWhiteList(address) {
      return (
        !/[^A-Za-z0-9./\-,\\ ']+/g.test(address) ||
        i18n.global.t("StreetAddressForm.invalidCharacter")
      );
    },
    cityWhiteList() {
      return (
        !/[^A-Za-z '.]+/g.test(this.form.city) ||
        i18n.global.t("StreetAddressForm.invalidCharacter")
      );
    },
  },
};
</script>
<style>
.mn-state-alignment .v-label.v-field-label {
  --v-field-padding-start: 0 !important;
}

.mn-state-alignment .v-field__input {
  --v-field-padding-start: 0;
}
</style>
