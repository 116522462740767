<template>
  <button
    v-bind="props"
    class="d-flex align-center"
    :aria-label="$t('TheUserMenuButton.btnLabel')"
    data-test="user-menu-btn"
  >
    <v-sheet
      class="d-flex align-center justify-center clipped-corner text-white font-weight-bold"
      height="32"
      width="32"
      :color="$route.name === 'profile' ? 'accent' : 'primary'"
      data-test="nav-bar-clipped-square"
    >
      <svg-icon
        class="icon-offset"
        color="white"
        data-test="nav-bar-profile-icon"
        type="mdi"
        :path="mdiAccount"
      >
      </svg-icon>
    </v-sheet>
    <svg-icon
      size="24"
      color="primary"
      type="mdi"
      :path="mdiChevronDown"
    ></svg-icon>
  </button>
</template>

<script>
import { mdiAccount, mdiChevronDown } from "@mdi/js";

export default {
  name: "TheUserMenuButton",
  data() {
    return {
      mdiAccount,
      mdiChevronDown,
    };
  },
};
</script>

<style scoped>
.clipped-corner {
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 50%, 50% 0);
}

.icon-offset {
  margin: 4px 4px 0 0;
}
</style>
