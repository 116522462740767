<template>
  <div
    v-if="payout && isPaypal"
    class="d-flex flex-column justify-center align-center"
  >
    <v-container v-if="gshowPaypalLoader" style="height: 400px">
      <v-row align-content="center" class="fill-height" justify="center">
        <v-col class="text-center loadercontainer" cols="12">
          {{ $t("ConfirmDisbursePayout.loadermessage") }}
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            height="6"
            indeterminate
            rounded
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <v-spacer class="pb-10" />
    <v-icon
      v-if="!isPaymentDeclined && !gshowPaypalLoader"
      class="pt-15"
      color="success"
      size="75"
      data-test="check-mark-icon"
    >
      mdi-check-decagram-outline
    </v-icon>
    <v-spacer class="pb-5" />

    <div
      v-if="!gshowPaypalLoader"
      class="text-h3 mt-15 mb-8"
      :class="{ 'text-success ': claimed }"
      data-test="payout-success-message"
    >
      <div v-if="inReview">
        {{
          $t("ConfirmDisbursePayout.inReviewMessage", {
            merchant: formattedGroupName,
          })
        }}
      </div>
      <div v-if="inPaymentReviewOrHoldStatus">
        {{ $t("ConfirmDisbursePayout.inPaymentReviewOrHoldMessage") }}
      </div>
      <div v-if="isPaymentDeclined">
        {{ $t("ConfirmDisbursePayout.payoutDeclined") }}
      </div>
      <div v-if="guetDisplaygenricMessage">{{ successMsg }}</div>
    </div>
  </div>

  <!-- This for the Other than paypal method -->

  <div
    v-if="payout && !isPaypal"
    class="d-flex flex-column justify-center align-center"
  >
    <v-spacer class="pb-10" />

    <v-icon class="pt-15" color="success" size="75" data-test="check-mark-icon">
      mdi-check-decagram-outline
    </v-icon>

    <v-spacer class="pb-5" />

    <div
      class="text-h3 mt-15 mb-8"
      :class="{ 'text-success ': claimed, 'text-center': isVisaPlus }"
      data-test="payout-success-message"
    >
      {{
        inReview
          ? $t("GuestTransferSuccess.inReviewMessage", {
              merchant: formattedGroupName,
            })
          : inPaymentReviewOrHoldStatus
          ? $t("ConfirmDisbursePayout.inPaymentReviewOrHoldMessage")
          : successMsg
      }}
    </div>
    <!-- Enabled By Visa+-->
    <div
      v-if="isVisaPlus"
      class="align-center justify-center enabledByVisaGuest"
    >
      <enabledby-Visa data-test="EnabledBy-Visalogo"> </enabledby-Visa>
    </div>
    <template v-if="claimed">
      <div data-test="claimed-notice">
        {{ $t("GuestTransferSuccess.claimedNotice") }}
      </div>

      <div class="my-10" data-test="claimed-instruction">
        {{
          $t("GuestTransferSuccess.claimedInstruction", {
            txId: payout.transactionId,
          })
        }}
      </div>
    </template>

    <template v-else>
      <div v-if="isECheck" class="pt-5" data-test="eCheck-info">
        {{ $t("GuestTransferSuccess.eCheckMessage") }}
      </div>

      <div class="pt-10" data-test="processed-date">
        <span class="font-weight-bold"
          >{{
            inReview
              ? $t("GuestTransferSuccess.paymentAccepted")
              : $t("GuestTransferSuccess.processed")
          }} </span
        >{{ currentDate() }}
      </div>

      <div v-if="inReview" data-test="payout-expected-deposit-date">
        <span class="font-weight-bold">
          {{ $t("GuestTransferSuccess.payoutExpectedDepositDate") }} </span
        >{{ shortDate(payoutExpectedDepositDate) }}
      </div>

      <div class="pt-2" data-test="transaction-id">
        <span class="font-weight-bold"
          >{{ $t("GuestTransferSuccess.transactionId") }}
        </span>
        {{ payout.transactionId }}
      </div>

      <v-spacer class="pb-5" />
    </template>

    <div
      v-if="checkImageUrl"
      class="d-flex align-center w-full pt-8 justify-center"
    >
      <v-img
        :src="checkImageUrl"
        data-test="check-image"
        class="img-max-width"
      />
    </div>
    <div v-if="hideCreateAccount" class="pt-3 caratRegisterBtn">
      <v-btn
        class="mx-2"
        tile
        variant="outlined"
        elevation="0"
        color="primary"
        min-width="125"
        :to="{ name: 'registration' }"
        data-test="register-btn"
      >
        {{ $t("GuestTransferSuccess.createAccount") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiCheckDecagramOutline } from "@mdi/js";
import { mapState } from "pinia";
import { format } from "date-fns";
import { usePayoutStore } from "@/stores/payout";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import { shortDate } from "@/filters/filters";
import EnabledbyVisa from "@/assets/img/enabledbyVisa.svg?inline";

import {
  isClaimed,
  isMultiRecipient,
  getOtherRecipient,
  getRecipient,
  isIR,
  isPaymentReviewOrHoldStatus,
  isPaymentInprogress,
  isDeclined,
} from "@/utils/payout";
import merchants from "@/utils/merchants";
import { useAuthStore } from "@/stores/auth";
import { ref, computed } from "vue";
import { currency } from "@/filters/filters";
export default {
  name: "GuestTransferSuccess",
  components: {
    EnabledbyVisa,
  },

  props: {
    checkImageUrl: { type: String, default: "" },
    mfaEnabled: { type: Boolean, default: false, required: true },
  },

  setup() {
    const payoutMethodStore = usePayoutMethodStore();
    const authStore = useAuthStore();
    const payoutStore = usePayoutStore();
    const inReview = ref(false);
    const payoutExpectedDepositDate = ref("");
    const inPaymentReviewOrHoldStatus = ref(false);
    const iRObj = isIR();
    inReview.value = iRObj.isIR;
    const paymentReviewObj = isPaymentReviewOrHoldStatus("PR");
    const paymentHoldObj = isPaymentReviewOrHoldStatus("PH");
    inPaymentReviewOrHoldStatus.value =
      paymentReviewObj.PR || paymentHoldObj.PH;

    const declinestat = computed(() => {
      return isDeclined(payoutStore.selected);
    });

    if (inReview.value) {
      payoutExpectedDepositDate.value = iRObj.payoutExpectedDepositDate;
      useTitle(i18n.global.t("GuestTransferSuccess.inReviewTitle"));
    } else {
      if (declinestat.value) {
        useTitle(i18n.global.t("ConfirmDisbursePayout.inDeclineTitle"));
      } else {
        useTitle(i18n.global.t("GuestTransferSuccess.title"));
      }
    }

    return {
      payoutMethodStore,
      authStore,
      payoutStore,
      inReview,
      payoutExpectedDepositDate,
      shortDate,
      inPaymentReviewOrHoldStatus,
    };
  },

  data() {
    return {
      mdiCheckDecagramOutline,
      guestUser: this.authStore.retriveRegistrationStatus,
      gshowPaypalLoader: false,
      gTotalTimespan: 30,
      gCurrentTimespan: 1,
      gIntervalId: null,
      guetDisplaygenricMessage: false,
      visaPlusAmount: "",
      visaPlusPayeeName: "",
    };
  },
  mounted() {
    if (this.isPaypal) {
      this.gshowPaypalLoader = true;
      this.gStartTimer();
    }

    if (this.isVisaPlus) {
      this.visaPlusPayeeName =
        this.payoutMethodStore.selected?.visaPlus?.payName;
      this.visaPlusAmount = currency(
        this.payout?.recipient[0]?.payments?.amount?.total,
        this.payout?.recipient[0]?.payments?.amount?.currency
      );
    }
  },
  beforeUnmount() {
    if (this.gIntervalId) clearInterval(this.gIntervalId);
  },

  computed: {
    ...mapState(usePayoutStore, { payout: "selected" }),

    successMsg() {
      return this.claimed
        ? i18n.global.t("GuestTransferSuccess.payoutClaimed")
        : this.isVisaPlus
        ? i18n.global.t("ConfirmDisbursePayout.payoutVisaPlusSuccess", {
            amount: this.visaPlusAmount,
            payeeName: this.visaPlusPayeeName,
          })
        : i18n.global.t("GuestTransferSuccess.payoutSuccess");
    },
    updatePageTitle() {
      return isDeclined(this.payout)
        ? i18n.global.t("ConfirmDisbursePayout.inDeclineTitle")
        : "";
    },

    claimed() {
      return isClaimed(this.payout);
    },
    isPaymentDeclined() {
      return isDeclined(this.payout);
    },
    // PAYPAL
    isPaypal() {
      if (isMultiRecipient(this.payout)) {
        return (
          getRecipient(this.payout).source === "PAYPAL" ||
          getOtherRecipient(this.payout).source === "PAYPAL"
        );
      } else {
        return this.payoutMethodStore.selected.source === "PAYPAL";
      }
    },
    // IS Visa+
    isVisaPlus() {
      if (isMultiRecipient(this.payout)) {
        return (
          getRecipient(this.payout).source === "VISAPLUS" ||
          getOtherRecipient(this.payout).source === "VISAPLUS"
        );
      } else {
        if (this.payoutMethodStore.selected)
          return this.payoutMethodStore.selected.source === "VISAPLUS";
        else return false;
      }
    },
    isECheck() {
      if (isMultiRecipient(this.payout)) {
        return (
          getRecipient(this.payout).source === "ECHECK" ||
          getOtherRecipient(this.payout).source === "ECHECK"
        );
      } else {
        return this.payoutMethodStore.selected.source === "ECHECK";
      }
    },

    merchantHideCreateAccount() {
      return (
        merchants[this.payout.merchant.groupName.toLowerCase()]
          ?.hideCreateAccount ?? false
      );
    },

    hideCreateAccount() {
      return (
        !this.emailRegistered &&
        this.mfaEnabled &&
        !this.merchantHideCreateAccount &&
        !this.guestUser
      );
    },

    emailRegistered() {
      return this.authStore?.user?.emailRegistered;
    },

    formattedGroupName() {
      return this.authStore?.retriveFormattedGroupName;
    },
  },

  created() {},

  methods: {
    currentDate() {
      return format(new Date(), String(i18n.global.t("dateFormat.short")));
    },

    gStartTimer() {
      this.gIntervalId = setInterval(() => {
        this.chkGuesttrasactionStatus();
      }, 3 * this.gCurrentTimespan * 1000);
    },

    async chkGuesttrasactionStatus() {
      this.gCurrentTimespan++;
      await this.payoutStore.fetchSelected();

      if (!isPaymentInprogress(this.payout)) {
        clearInterval(this.gIntervalId);
        this.gshowPaypalLoader = false;
        if (this.isPaymentDeclined) {
          this.updatePageTitle;
        }

        if (!this.isPaymentDeclined && !this.inReview && !this.claimed) {
          this.guetDisplaygenricMessage = true;
        }
      } else if (this.gCurrentTimespan > 10) {
        this.guetDisplaygenricMessage = true;
        this.gshowPaypalLoader = false;
        clearInterval(this.gIntervalId);
      }
    },
  },
};
</script>

<style scoped>
.caratRegisterBtn a:hover {
  opacity: 1;
  background: #000000 !important;
  border: 2px solid #64ccff !important;
  color: rgb(var(--v-theme-on-primary)) !important;
}

.enabledByVisaGuest {
  width: 25%;
  padding-top: 5%;
}
@media (min-width: 961px) {
  .img-max-width {
    max-width: 37vw;
  }
}

@media (max-width: 960px) {
  .img-max-width {
    max-width: 74vw;
  }
}
</style>
