import ddp from "@/api/ddp-api";
import { defineStore } from "pinia";
import { has } from "lodash/object";
import { useAuthStore } from "@/stores/auth";
import { usePayoutSortfilterStore } from "@/stores/payout-sortfilter";
import { addMinutes, endOfDay, format, parse, startOfDay } from "date-fns";
import router from "@/router";

const state = () => {
  return {
    available: [],
    history: [],
    latest: [],
    totalAvailable: 0,
    historyFilters: {
      dateRange: {
        from: "",
        to: "",
      },
      merchant: {
        merchant: "",
      },
      filterStatus: {
        filterStatus: "",
      },
    },
    historyPagination: {
      pages: {
        currentPage: 0,
        firstPage: true,
        lastPage: false,
        totalRecords: 0,
        currentPageRecords: 0,
      },
      pageSize: 10,
    },
    tmxConfig: {
      organizationId: "",
      apiKey: "",
      policy: "",
      tmxEnabled: false,
      url: "",
    },
    portalConfigurations: null,
    summary: null,
    selected: null,
    payoutTypeSelectedSource: "",
    formCurrentLandingState: "",
    disburseResult: null,
    tmxId: null,
    securityCodeRequired: false, // As per this flag we are displaying CVV field in Debit Card form
    disableCardExpiry: false, // for displaying/hidding expiry date in debit card form
    cvvVal: "",
    savePayoutAccount: "false",
    nycbgovtLinkClicked: false,
    isRTPFailed: false,
  };
};

const getters = {
  availablePayoutAmount() {
    if (!this.available.length) return 0;

    return this.available.reduce(
      (sum, item) => sum + item.totalTransactionAmount.total,
      0
    );
  },
  getRTPaccountCapability() {
    return this.isRTPFailed;
  },
  getTmxInfo() {
    return this.tmxConfig;
  },
  getCVVfieldConfig() {
    return this.securityCodeRequired;
  },

  getDisableCardExpiryConfig() {
    return this.disableCardExpiry;
  },

  getCVVVal() {
    return this.cvvVal;
  },
  getPaypalgovtLink() {
    return this.nycbgovtLinkClicked;
  },
  summaryHasDisbursedAmount() {
    return has(this.summary, "disbursedAmount.groupByMerchant");
  },

  getPortalConfigData(state) {
    return state.portalConfigurations;
  },

  getPayoutTypeSelectedSource() {
    return this.payoutTypeSelectedSource;
  },

  getFormCurrentLandingState() {
    return this.formCurrentLandingState;
  },

  utcAdjustedHistoryFiltersDateRange() {
    if (
      this.historyFilters.dateRange.from === "" ||
      this.historyFilters.dateRange.to === ""
    ) {
      return { from: "", to: "" };
    }

    const from = shiftLocalToUTC(
      this.historyFilters.dateRange.from,
      startOfDay
    );
    const to = shiftLocalToUTC(this.historyFilters.dateRange.to, endOfDay);

    return { from, to };
  },
};

const referenceDate = new Date();

function shiftLocalToUTC(localString, shifter) {
  const fmt = "yyyyMMdd";
  const local = parse(localString, fmt, referenceDate);

  // startOfDay, endOfDay, etc
  const shiftedLocal = shifter(local);
  const utc = addMinutes(shiftedLocal, referenceDate.getTimezoneOffset());

  return format(utc, fmt);
}

const actions = {
  async fetchAvailable(pageSize = 99) {
    const authStore = useAuthStore();
    const res = await ddp.getAvailablePayouts(
      authStore.user.recipientId,
      pageSize
    );

    this.available = res.data.content ?? [];
    this.totalAvailable = res.data.totalRecords;

    return res;
  },

  async fetchSummary() {
    const authStore = useAuthStore();
    const r = await ddp.getTransactionSummary(authStore.user.recipientId);

    this.summary = r.data;

    return r;
  },

  async fetchHistory(page) {
    const authStore = useAuthStore();
    const payoutSortfilterStore = usePayoutSortfilterStore();
    const r = await ddp.getTransactionHistory(
      authStore.user.recipientId,
      page,
      this.historyPagination.pageSize,
      this.utcAdjustedHistoryFiltersDateRange.from,
      this.utcAdjustedHistoryFiltersDateRange.to,
      this.historyFilters.merchant.merchant,
      this.historyFilters.filterStatus.filterStatus
    );

    this.history = r.data.content ?? [];
    payoutSortfilterStore.uiSortManagement.filteredHistory =
      r.data.content ?? [];

    const {
      currentPageNumber,
      firstPage,
      lastPage,
      totalRecords,
      currentPageRecords,
    } = r.data;

    this.historyPagination.pages = {
      currentPage: currentPageNumber,
      firstPage,
      lastPage,
      totalRecords,
      currentPageRecords,
    };

    return r;
  },

  async fetchLatest() {
    const authStore = useAuthStore();
    const r = await ddp.getLast5Payouts(authStore.user.recipientId);
    this.latest = r.data.content ?? [];
    return r;
  },

  async fetchMerchantConfig(merchantID) {
    const r = await ddp.getMechantConfigration(merchantID);
    this.tmxConfig = r.data.tmxConfig;
    this.portalConfigurations = r.data.configurations;
    let hasConfigrations = Object.prototype.hasOwnProperty.call(
      r.data,
      "configurations"
    );

    if (hasConfigrations) {
      this.disableCardExpiry =
        r.data.configurations.DISABLE_CARD_EXPIRY === "TRUE" ? true : false;
      if (r.data.configurations.SECURITY_CODE_REQUIRED === "TRUE") {
        this.securityCodeRequired = true;
      } else {
        this.securityCodeRequired = false;
      }
    } else {
      this.securityCodeRequired = false;
      this.disableCardExpiry = false;
    }

    return r;
  },

  reject({ recipientId, transactionId, paymentId, requestBody }) {
    return ddp.rejectPayment(
      recipientId,
      transactionId,
      paymentId,
      requestBody
    );
  },

  getUriWithParam(baseUrl, params) {
    const Url = new URL(baseUrl);
    const urlParams = new URLSearchParams(Url.search);
    for (const key in params) {
      if (params[key] !== undefined) {
        urlParams.set(key, params[key]);
      }
    }
    Url.search = urlParams.toString();
    return Url.toString();
  },

  setRTPaccountCability(val) {
    this.isRTPFailed = val;
  },
  setCVVVal(val) {
    this.cvvVal = val;
  },

  setTmxId(param) {
    this.tmxId = param;
  },
  setsecurityCodeRequired(param) {
    this.securityCodeRequired = param;
  },
  setPaypalgovtLink(param) {
    this.nycbgovtLinkClicked = param;
  },
  setdisableCardExpiry(param) {
    this.disableCardExpiry = param;
  },
  setPayoutTypeSelectedsource(param) {
    this.payoutTypeSelectedSource = param;
  },

  setFormCurrentLandingState(param) {
    this.formCurrentLandingState = param;
  },

  setSavePayoutAccount(param) {
    this.savePayoutAccount = param;
  },

  async disburse(disbursePaymentDetails) {
    // Added tmxId Parameter for disburse api
    disbursePaymentDetails.accountDetails.tmxId = this.tmxId;
    /*start: set savePayoutAccount*/
    disbursePaymentDetails.accountDetails.savePayoutAccount =
      this.savePayoutAccount;
    this.setSavePayoutAccount("false");
    /*end: set savePayoutAccount*/
    const authStore = useAuthStore();
    try {
      const r = await ddp.disbursePayout(
        authStore.user.recipientId,
        this.selected.transactionId,
        disbursePaymentDetails.paymentId,
        disbursePaymentDetails.accountDetails
      );

      this.disburseResult = r.data;

      return r;
    } finally {
      await this.fetchSelected();
    }
  },
  resetCVVField() {
    this.securityCodeRequired = false;
  },
  resetDisableCardExpiry() {
    this.disableCardExpiry = false;
  },
  fetchMerchants() {
    const authStore = useAuthStore();
    return ddp.getTransactionMerchant(authStore.user.recipientId);
  },

  approve({ transactionId, paymentId }) {
    const authStore = useAuthStore();
    return ddp
      .approvePayout(authStore.user.recipientId, transactionId, paymentId)
      .finally(async () => {
        await this.fetchSelected();
      });
  },

  async fetchSelected() {
    const authStore = useAuthStore();

    const txId = this.selected
      ? this.selected.transactionId
      : authStore.ak.transactionId;
    const r = await ddp.getPayout(authStore.user.recipientId, txId);

    this.selected = r.data;

    return r;
  },

  refreshPayouts() {
    this.fetchAvailable();
    this.fetchSummary();

    if (router.currentRoute.name === "dashboard") {
      this.fetchLatest();
    }

    if (router.currentRoute.name === "payouts" && this.history.length > 0) {
      this.fetchHistory(this.historyPagination.pages.currentPage);
    }
  },
};

export const usePayoutStore = defineStore("payout", {
  state,
  getters,
  actions,
});
