<script>
import { mdiArrowTopRight } from "@mdi/js";
import { i18n } from "@/plugins/i18n";

export default {
  props: {
    bold: { type: Boolean, default: false },
    arlLbl: { type: String, default: "" },
  },
  setup() {
    return {
      mdiArrowTopRight,
    };
  },
  computed: {
    getArilaLbl() {
      return i18n.global.t("ReadMoreTooltip.readMoreAria");
    },
  },
};
</script>

<template>
  <VTooltip top max-width="300" :open-on-click="true">
    <template #activator="{ on, props }">
      <button
        data-test="read-more-btn"
        v-bind="props"
        :class="{ 'font-weight-bold': bold }"
        :aria-label="getArilaLbl + arlLbl"
        v-on="on"
      >
        {{ $t("ReadMoreTooltip.readMore") }}

        <VIcon color="primary" size="1.1rem" data-test="tooltip-top-right-icon"
          >mdi-arrow-top-right
        </VIcon>
      </button>
    </template>

    <span data-test="tooltip-message" aria-live="polite">
      <slot />
    </span>
  </VTooltip>
</template>
