import NewPayoutMethods from "@/components/payout-methods/NewPayoutMethods";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useDynamicContent } from "@/composables/dynamic-content";
import { useBack } from "@/composables/back";

export function useTransientPayoutMethod() {
  const { setContent } = useDynamicContent();

  useBack(() => setContent(NewPayoutMethods));

  const payoutMethodStore = usePayoutMethodStore();
  const store = (apiSchema) => {
    payoutMethodStore.selected = apiSchema;
  };

  return { store };
}
