import { useAuthStore } from "@/stores/auth";
import router from "@/router";
import { v4 } from "uuid";
import { useAppStore } from "@/stores/app";

export function auth(cfg) {
  const authStore = useAuthStore();
  if (authStore && authStore.session) {
    cfg.headers["Authorization"] = `Bearer ${authStore.session.tokenId}`;
  }

  return cfg;
}

export function requestId(cfg) {
  cfg.headers["Client-Request-Id"] = v4();

  return cfg;
}

export function traceId(cfg) {
  const appstore = useAppStore();
  cfg.headers["X-Portal-TraceId"] = appstore.getTraceId;
  return cfg;
}

export async function unauthorizedError(err) {
  const authStore = useAuthStore();
  if (err.response && err.response.status === 401) {
    if (router.currentRoute.name !== "session-ended" && authStore.isUser) {
      await router
        .push({
          name: "session-ended",
          query: { guest: authStore.isGuest.toString() },
        })
        .then(() => {
          authStore.$reset();
        });
    } else if (
      router.currentRoute.name !== "session-ended" &&
      authStore.isGuest
    ) {
      router.push({
        name: "session-ended",
        query: { guest: "true", source: "exit" },
      });
      authStore.$reset();
    }
    return Promise.resolve(err.response);
  }
  return Promise.reject(err);
}
