<script>
import { mdiStorefrontOutline } from "@mdi/js";
import FallbackLogo from "@/assets/img/merchants/fallback_logo.svg?inline";
import LoadingLogo from "@/assets/img/merchants/loading_logo.svg?inline";
import { ref, computed } from "vue";
import "external-svg-loader";
import { useStaticStore } from "@/stores/static";
import merchants from "@/utils/merchants";

export default {
  components: { FallbackLogo, LoadingLogo },

  props: {
    merchant: { type: String, default: "fallback" },
    size: { type: String, default: "1rem" },
    backdrop: { type: Boolean, default: false },
    colorOverride: { type: String, required: false, default: "" },
  },
  setup(props) {
    const logoOverrides = ref(null);
    const logoColor = ref(null);
    const logoImage = ref(null);
    const loading = ref(false);
    const staticStore = useStaticStore();

    const merchantLogoFromAWSEnabled = computed(() => {
      return process.env.VUE_APP_MERCHANT_LOGO_FROM_AWS === "true";
    });

    // MERCHANT
    const merchantLower = computed(() => props.merchant.toLowerCase());
    const merchantLogo = computed(() => {
      try {
        return require(`@/assets/img/merchants/${merchantLower.value}.svg?inline`);
      } catch (_) {
        return false;
      }
    });

    const merchantColor = computed(() => {
      if (loading.value) {
        return "#ffffff";
      }

      if (props.colorOverride) {
        return props.colorOverride;
      }

      if (!merchantLogoFromAWSEnabled.value) {
        if (!merchantLogo.value) {
          return "#f60";
        }
      } else if (!logoImage.value || !logoColor.value) {
        return "#f60";
      }

      if (!merchantLogoFromAWSEnabled.value) {
        const merchant = merchants[merchantLower.value];
        return merchant && merchant.color ? merchant.color : "#ffffff";
      } else {
        return logoImage.value && logoColor.value ? logoColor.value : "#ffffff";
      }
    });

    // CLASSES AND STYLES
    const color = computed(() =>
      props.backdrop ? "#ffffff" : merchantColor.value
    );

    const style = computed(() => ({
      "--merchant-color": merchantColor.value,
      width:
        logoOverrides.value != null && logoOverrides.value.width != null
          ? logoOverrides.value.width
          : props.size,
      height:
        logoOverrides.value != null && logoOverrides.value.height != null
          ? logoOverrides.value.height
          : props.size,
      "min-width":
        logoOverrides.value != null ? logoOverrides.value.width : props.size,
    }));

    return {
      mdiStorefrontOutline,
      logoImage,
      logoColor,
      logoOverrides,
      color,
      merchantColor,
      style,
      loading,
      staticStore,
      merchantLogoFromAWSEnabled,
      merchantLogo,
    };
  },

  watch: {
    merchant(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getMerchantLogoColorDetails();
        this.getMerchantLogoImageDetails();
      }
    },
  },

  mounted() {
    this.getMerchantLogoColorDetails();
    this.getMerchantLogoImageDetails();
  },

  methods: {
    getMerchantLogoColorDetails() {
      if (this.merchantLogoFromAWSEnabled) {
        if (
          this.staticStore.color[this.merchant.toLowerCase()] !== undefined &&
          this.staticStore.color[this.merchant.toLowerCase()] !== null
        ) {
          this.logoColor = this.staticStore.color[this.merchant.toLowerCase()];
        } else {
          this.loading = true;
          this.staticStore
            .fetchMerchantLogoColor(this.merchant.toLowerCase())
            .then((res) => {
              this.logoColor = res.color;
              if (res.override) this.logoOverrides = res.override;
            })
            .catch(() => {
              this.logoColor = false;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },

    getMerchantLogoImageDetails() {
      if (this.merchantLogoFromAWSEnabled) {
        if (
          this.staticStore.url[this.merchant.toLowerCase()] !== undefined &&
          this.staticStore.url[this.merchant.toLowerCase()] !== null
        ) {
          this.logoImage = this.staticStore.url[this.merchant.toLowerCase()];
        } else {
          this.loading = true;
          this.staticStore
            .fetchMerchantLogoImage(this.merchant.toLowerCase())
            .then((res) => {
              this.logoImage = res.request.responseURL;
            })
            .catch(() => {
              this.logoImage = false;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>

<template>
  <div
    class="d-flex justify-center align-center"
    :class="{ backdrop }"
    :style="{ ...style, overflow: 'hidden', padding: '10px 10px' }"
    data-test="merchant-logo-container"
  >
    <div v-if="!merchantLogoFromAWSEnabled">
      <Component
        :is="merchantLogo"
        v-if="merchantLogo"
        data-test="merchant-logo-svg"
        :width="style.width"
        :aria-hidden="true"
        role="img"
        :style="{ color }"
      />

      <FallbackLogo
        v-else
        data-test="fallback-logo-svg"
        :width="style.width"
        :aria-hidden="true"
        role="img"
        :style="{ color }"
      />
    </div>
    <div v-else>
      <div v-if="loading" data-src="merchant-loading-logo">
        <LoadingLogo
          :width="style.width"
          :aria-hidden="true"
          role="img"
          :style="{ color }"
        />
      </div>
      <div v-else>
        <svg
          v-if="logoImage && logoColor"
          :data-src="logoImage"
          :style="{ color }"
          :width="style.width"
          :aria-hidden="true"
          data-test="merchant-logo-svg"
        />

        <FallbackLogo
          v-else
          data-test="fallback-logo-svg"
          :width="style.width"
          :aria-hidden="true"
          role="img"
          :style="{ color }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.backdrop {
  background-color: var(--merchant-color);
}
</style>
