<template>
  <v-text-field
    v-model="vmodelValue"
    :label="label"
    :rules="combinedRules"
    :append-inner-icon="appendIcon"
    v-bind="$attrs"
    variant="underlined"
  >
    <template #prepend-inner>
      <slot name="prepend-inner" />
    </template>
  </v-text-field>
</template>

<script>
import emojiRegexConstructor from "emoji-regex";
import { i18n } from "@/plugins/i18n";
import { computed } from "vue";

const emojiRegex = emojiRegexConstructor();

export default {
  name: "AppTextField",

  inheritAttrs: false,

  props: {
    modelValue: { type: String, default: null },
    label: { type: [String, Object], default: "" },
    rules: { type: Array, default: () => [] },
    appendIcon: { type: String, default: null },
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const vmodelValue = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        emit("update:modelValue", newValue);
      },
    });

    return { vmodelValue };
  },

  data() {
    return {
      baseRules: [this.noEmoji],
    };
  },

  computed: {
    combinedRules() {
      return [...this.rules, ...this.baseRules];
    },
  },

  methods: {
    noEmoji(v) {
      emojiRegex.lastIndex = 0;
      return (
        !emojiRegex.test(v) ||
        i18n.global.t("AppTextField.invalidChars", { label: this.label })
      );
    },
  },
};
</script>
