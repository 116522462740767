<template>
  <div class="d-flex my-1">
    <v-badge inline dot left :color="requirementMet ? 'success' : 'grey'">
      <div
        class="ml-2 primary--text"
        :class="{ 'secondary--text': !requirementMet }"
      >
        {{ text }}
      </div>
    </v-badge>
  </div>
</template>

<script>
export default {
  name: "PasswordRequirement",

  props: {
    text: { type: String, default: "" },
    requirementMet: { type: Boolean, default: false },
  },
};
</script>
