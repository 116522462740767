<template>
  <div>
    <div class="text-center mb-4">
      {{ $t("MultiRecipientStatusOther.title") }}
    </div>

    <div class="py-5 px-2 b-4-background">
      <v-row no-gutters align="center">
        <v-col
          cols="6"
          class="font-weight-bold text-capitalize"
          data-test="other-recipient-name"
        >
          {{ name }}
        </v-col>

        <v-col cols="6">
          <recipient-status :payout="payout" other />
        </v-col>
      </v-row>

      <v-row v-if="showAccount" no-gutters class="mt-3">
        <v-col cols="6">
          <span class="font-weight-bold mr-2">{{
            $t("MultiRecipientStatusOther.payoutMethodLabel")
          }}</span>
          <span data-test="other-recipient-claimed-account-type">{{
            payoutMethodDisplayNames[otherRecipient.source]
          }}</span>
        </v-col>

        <v-col v-if="showAccountDetails" cols="6">
          <span class="font-weight-bold mr-2" data-test="account-label">
            {{ $t("MultiRecipientStatusOther.accountNumberLabel") }}</span
          >
          <span data-test="account-data" class="break-word">{{
            otherRecipient.alias
          }}</span>
        </v-col>
      </v-row>
    </div>

    <div
      v-if="payout.status !== 'rejected'"
      class="font-italic fine-print mt-5"
    >
      {{ $t("MultiRecipientStatusOther.finePrint") }}
    </div>
  </div>
</template>

<script>
import RecipientStatus from "@/components/RecipientStatus";

import { payoutMethodDisplayNames } from "@/utils/payout-methods";
import {
  getOtherRecipient,
  getOtherRecipientPaymentStatus,
  isDisbursed,
} from "@/utils/payout";

export default {
  name: "MultiRecipientStatusOther",

  components: { RecipientStatus },

  props: {
    payout: { type: Object, required: true },
  },

  setup() {
    return {
      payoutMethodDisplayNames,
    };
  },

  computed: {
    name() {
      return this.otherRecipient.firstName && this.otherRecipient.lastName
        ? `${this.otherRecipient.firstName.toLowerCase()} ${this.otherRecipient.lastName.toLowerCase()}`
        : this.otherRecipient.doingBusinessAs;
    },

    otherClaimed() {
      const otherStatusCode = getOtherRecipientPaymentStatus(this.payout);
      return (
        otherStatusCode === "DS" ||
        otherStatusCode === "IP" ||
        otherStatusCode === "IR" ||
        otherStatusCode === "PR" ||
        otherStatusCode === "DI"
      );
    },

    showAccountDetails() {
      return this.otherRecipient.source !== "ECHECK";
    },

    showAccount() {
      return this.otherClaimed && !isDisbursed(this.payout);
    },

    otherRecipient() {
      return getOtherRecipient(this.payout);
    },
  },
};
</script>

<style scoped>
.b-4-background {
  /*border: 4px solid var(--v-background-base);*/

  border: 4px solid #f1f1f1;
}

.fine-print {
  font-size: 0.75rem;
}

.break-word {
  word-wrap: break-word;
}
</style>
