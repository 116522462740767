<template>
  <div class="d-flex align-center">
    <div class="font-weight-bold">{{ $t("RecipientStatus.label") }}</div>
    <div class="mx-2" data-test="recipient-status">
      {{ status.text }}
    </div>

    <v-icon
      v-if="claimed"
      color="success"
      size="20"
      data-test="recipient-claimed-icon"
    >
      mdi-check-circle
    </v-icon>

    <v-badge
      v-else
      aria-hidden="true"
      :color="status.color"
      inline
      data-test="recipient-badge"
    />
  </div>
</template>

<script>
import { mdiCheckCircle } from "@mdi/js";
import {
  getOtherRecipientPaymentStatus,
  getOtherRecipientPaymentStatusDisplayConfig,
  getPaymentStatus,
  getPaymentStatusDisplayConfig,
} from "@/utils/payout";

export default {
  name: "RecipientStatus",

  props: {
    other: { type: Boolean, default: false },
    payout: { type: Object, required: true },
  },

  data() {
    return {
      mdiCheckCircle,
    };
  },

  computed: {
    status() {
      return this.other
        ? getOtherRecipientPaymentStatusDisplayConfig(this.payout)
        : getPaymentStatusDisplayConfig(this.payout);
    },

    statusCode() {
      return this.other
        ? getOtherRecipientPaymentStatus(this.payout)
        : getPaymentStatus(this.payout);
    },

    claimed() {
      return (
        this.statusCode === "DS" ||
        this.statusCode === "IP" ||
        this.statusCode === "IR" ||
        this.statusCode === "PR"
      );
    },
  },
};
</script>
