import { inject, onBeforeUnmount, onMounted } from "vue";
import noop from "lodash/noop";

export function useBack(backFn) {
  const setBack = inject("setBack", noop);

  onMounted(() => setBack(backFn));
  onBeforeUnmount(() => setBack(null));

  return { setBack };
}
