<template>
  <div @keyup.enter="keytoggle">
    <app-text-field
      v-model="vmodelValue"
      v-mask="mask"
      :label="label"
      :rules="rules"
      :append-inner-icon="showInput ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showInput ? 'text' : 'password'"
      role="textbox"
      v-bind="$attrs"
      autocomplete="off"
      aria-autocomplete="none"
      @click:append-inner="toggleShowInput"
    />
  </div>
</template>

<script>
import AppTextField from "@/components/forms/AppTextField";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { computed } from "vue";

export default {
  name: "SecureTextField",

  components: { AppTextField },

  inheritAttrs: false,

  props: {
    modelValue: { type: String, default: null },
    label: { type: [String, Object], default: "" },
    rules: { type: Array, default: () => [] },
    mask: { type: String, default: "" },
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const vmodelValue = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        emit("update:modelValue", newValue);
      },
    });

    return {
      vmodelValue,
    };
  },

  data() {
    return {
      showInput: false,
      timeoutId: null,
      mdiEye,
      mdiEyeOff,
    };
  },

  mounted() {
    const nodeList = document.querySelectorAll("button.v-icon");
    nodeList.forEach((node) => {
      node.setAttribute("aria-pressed", "false");
    });
  },

  methods: {
    keytoggle(evt) {
      if (evt.target.getAttribute("role") === "button") {
        this.showInput = !this.showInput;
      }
    },

    toggleShowInput(event) {
      this.showInput = !this.showInput;
      let child = event.currentTarget;
      if (this.showInput) {
        child.setAttribute("aria-pressed", "true");
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }

        this.timeoutId = setTimeout(() => (this.showInput = false), 10000);
      } else {
        child.setAttribute("aria-pressed", "false");
      }
    },
  },
};
</script>
