/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import colors from "vuetify/lib/util/colors";
// Composables
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { mdiAlert, mdiAlertOctagonOutline } from "@mdi/js";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

export default createVuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#000000",
          secondary: "#535353",
          accent: "#ff6200",
          success: "#4B8500",
          error: "#CC0000",
          background: "#f1f1f1",
          sidebar: "#e3e3e3",
          panel: "#f4f4f4",
          authWarning: "#CC480F",
          "sidebar-panel": "#d9d9d9",
          "fiserv-blue": "#0096cf",
          "fiserv-dark-blue": colors.lightBlue.darken4,
          "fiserv-black": "#1f1f1f",
          "fiserv-gray-disabled": "#747272",
        },
      },
    },
  },
  icons: {
    iconfont: "mdiSvg",
    values: {
      warning: mdiAlert,
      error: mdiAlertOctagonOutline,
    },
  },
  components,
  directives,
});
