<template>
  <div class="d-flex flex-column">
    <payout-summary :payout="payout" data-test="payout-summary-section" />

    <e-check-form data-test="eCheck-form-section" />
  </div>
</template>
<script>
import PayoutSummary from "@/components/payouts/PayoutSummary";
import ECheckForm from "@/components/payout-methods/eCheck/ECheckForm";
import { mapState } from "pinia";
import { usePayoutStore } from "@/stores/payout";

export default {
  name: "RegisteredUserECheckForm",

  components: {
    PayoutSummary,
    ECheckForm,
  },

  computed: {
    ...mapState(usePayoutStore, { payout: "selected" }),
  },
};
</script>
