import { defineStore } from "pinia";
import auth from "@/api/auth-api";
import ddp from "@/api/ddp-api";
import * as forge from "node-forge";

const state = () => {
  return {
    user: null,
    userPreferences: null,
    session: null,
    merchantData: null,
    ak: null,
    error: {
      show: false,
      title: "",
      message: "",
    },
    warning: {
      show: false,
      title: "",
      message: "",
    },
    sessionId: "",
    otpConfigDefaultTimeOut: 30,
    secondaryOtpData: null,
    secondaryOTPChannel: null,
    getEmailId: null,
    notificationData: null,
    isFAQClicked: false,
  };
};

const getters = {
  isUser(state) {
    return !!state.user && !state.user.guest;
  },

  isGuest(state) {
    return !!state.user && state.user.guest;
  },

  publicKey(state) {
    return (
      !!state.session &&
      forge.pki.publicKeyFromPem(
        "-----BEGIN PUBLIC KEY-----" +
          state.session?.publicKey +
          "-----END PUBLIC KEY-----"
      )
    );
  },
  retriveSignBtnStatus(state) {
    return state && state.session?.profileOptions?.signIn;
  },
  retriveBankCode(state) {
    return (
      state &&
      state.session?.extraInfo?.find((item) => item.key === "copyright")?.value
    );
  },

  retriveFormattedGroupName(state) {
    return (
      state &&
      state.merchantData?.extraInfo?.find(
        (item) => item.key === "formattedGroupName"
      )?.value
    );
  },

  retriveRegistrationStatus(state) {
    return state && state.session?.profileOptions?.registration;
  },
  retriveSecondaryOTPChannel(state) {
    return state && state.secondaryOTPChannel;
  },

  retriveOTPChannels(state) {
    return state.session && state.session?.otpOptions;
  },

  getNotificationData(state) {
    return state.notificationData;
  },

  retriveOTPChannelsMerchant(state) {
    return (state.session && state.session?.merchantOtpOptions) || null;
  },

  retriveMerchantData(state) {
    return state.merchantData || null;
  },

  retriveConfigOtp(state) {
    return state.otpConfigDefaultTimeOut;
  },
};

const actions = {
  showAuthError(error) {
    this.error = { show: true, title: error.title, message: error.message };
  },

  clearAuthError() {
    this.error = { show: false, title: "", message: "" };
  },

  showAuthWarning(warning) {
    this.warning = {
      show: true,
      title: warning.title,
      message: warning.message,
    };
  },

  clearAuthWarning() {
    this.warning = { show: false, title: "", message: "" };
  },

  async signIn(credentials) {
    const res = await auth.signIn(credentials);
    this.session = res.data;
    // Mocking
    this.session = {
      ...this.session,
    };
    return res.data;
  },

  async currentAuthenticatedUser() {
    if (this.user) {
      return this.user;
    }

    if (!this.session) {
      throw new Error("no session");
    }

    const r = await this.fetchUser();

    return r.data.recipient;
  },

  async fetchUser() {
    const r = await ddp.getRecipient(this.session?.recipientId);
    this.user = r.data.recipient;

    return r;
  },

  async fetchUserPreferences() {
    const r = await ddp.getRecipientPreferences(this.session?.recipientId);
    this.userPreferences = r.data;

    return r;
  },

  createOtp(payload) {
    return ddp.createOtp(payload);
  },

  async validateOtp(req) {
    const r = await auth.validateOTP(req.payload, req.sessionId);
    this.session = {
      ...this.session,
      tokenId: r.headers["x-amzn-remapped-authorization"].split(" ")[1],
    };

    return r;
  },

  signOut() {
    return ddp.signOut().finally(() => {
      this.user = null;
      this.session = null;
    });
  },

  async signUp(data) {
    return await ddp.signUp(data);
  },

  linkPayment(data) {
    return ddp.linkPayment(this.ak.paymentId, data);
  },

  async createLimitedAccessSession() {
    const r = await auth.createAccessToken(this.ak.decoded);
    this.session = r.data;
    const resendOTPEnableDurationInfo = this.session?.extraInfo.find(
      (item) => item.key === "RESEND_OTP_ENABLE_DURATION"
    );
    const resendTimeout = resendOTPEnableDurationInfo
      ? +resendOTPEnableDurationInfo.value
      : undefined;
    this.otpConfigDefaultTimeOut =
      resendTimeout || this.otpConfigDefaultTimeOut;
    /*added : to handle old payment links after code refractoring*/
    this.ak["recipientId"] = r.data.recipientId;
    this.merchantData = r.data;

    return r;
  },

  async optIn() {
    const r = await ddp.updatePreferences(this.session?.recipientId, {
      optStatus: "IN",
    });
    this.session = { ...this.session, ...r.data };

    return r;
  },

  async updateUser(recipientData) {
    await ddp.updateProfile(this.session?.recipientId, recipientData);
    return await this.fetchUser();
  },

  updateUserPreferences(preferences) {
    return ddp.updateRecipientPreferences(
      this.session?.recipientId,
      preferences
    );
  },

  async optOutWithCancel() {
    const r = await ddp.updatePreferences(this.session?.recipientId, {
      optStatus: "OUT",
      paymentId: this.ak.paymentId,
    });
    this.session = { ...this.session, optStatus: r.data.optStatus };

    return r;
  },

  forgotPW(emailAddress) {
    return auth.forgotPassword(emailAddress);
  },

  resetPW(req) {
    return auth.resetPassword(req.payload, req.sessionId);
  },

  async getNotificationDataDDP() {
    return await ddp.getNotificationData();
  },

  setIsGuestFlow() {
    this.user.guest = true;
  },
  setFaqSelected(flag) {
    this.isFAQClicked = flag;
  },
};

export const useAuthStore = defineStore("auth", {
  state,
  getters,
  actions,
  persist: {
    storage: window.sessionStorage,
  },
});
