<template>
  <div ref="container" class="payout-method-select-container">
    <v-menu tile attach=".payout-method-select-container">
      <template #activator="{ on, props }">
        <v-sheet
          v-bind="props"
          class="activator w-full"
          color="panel"
          tag="button"
          data-test="payout-method-select-activator"
          :aria-label="$t('PayoutMethodSelect.activatorLabel')"
          v-on="on"
        >
          <div
            class="d-flex align-center justify-space-between px-5"
            :style="{ minHeight }"
          >
            <payout-method-card
              v-if="selected"
              :method="selected"
              class="w-full"
              data-test="payout-method-select-selection"
            />

            <div v-else data-test="payout-method-select-no-selection">
              {{ $t("PayoutMethodSelect.noSelection") }}
            </div>

            <svg-icon type="mdi" :path="mdiChevronDown"></svg-icon>
          </div>
        </v-sheet>
      </template>

      <v-list role="none">
        <payout-method-select-option
          v-for="(method, i) in methods"
          :key="i"
          :method="method"
          :class="{ 'border-b': i < methods.length - 1 }"
          class="payment-method-select-option"
          data-test="payment-method-select-option"
          @click="selected = method"
        />
      </v-list>
    </v-menu>

    <div
      v-if="!selected && showErrormsg"
      class="v-messages v-error"
      role="alert"
      aria-live="polite"
    >
      <div class="v-messages__message">This field is required.</div>
    </div>
  </div>
</template>

<script>
import { mdiChevronDown, mdiCreditCardPlus } from "@mdi/js";
import PayoutMethodSelectOption from "@/components/payouts/available/disbursements/PayoutMethodSelectOption";
import PayoutMethodCard from "@/components/payout-methods/PayoutMethodCard";
import NewPayoutMethods from "@/components/payout-methods/NewPayoutMethods";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useDynamicContent } from "@/composables/dynamic-content";
import { findDefaultPayoutMethod } from "@/utils/payout-methods";
import { VInput } from "vuetify/lib/components";

export default {
  name: "PayoutMethodSelect",

  components: { PayoutMethodCard, PayoutMethodSelectOption },

  extends: VInput,

  model: {
    event: "change",
  },

  props: {
    methods: { type: Array, required: true },
    minHeight: { type: String, default: "60px" },
    modelValue: { type: Object, default: null },
    showErrormsg: { type: Boolean, default: false },
  },

  emits: ["update:modelValue"],

  setup() {
    const payoutMethodStore = usePayoutMethodStore();

    const { setContent } = useDynamicContent();

    return {
      mdiChevronDown,
      mdiCreditCardPlus,
      NewPayoutMethods,
      payoutMethodStore,
      setContent,
    };
  },

  computed: {
    selected: {
      get() {
        return this.modelValue;
      },

      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
  },

  watch: {
    methods(newVal) {
      const d = findDefaultPayoutMethod(newVal);
      this.selected = d || null;
    },
    showErrormsg: function (newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
  },

  mounted() {
    this.initSelected();
  },

  methods: {
    initSelected() {
      const d = findDefaultPayoutMethod(this.methods);
      this.selected = d || null;
    },
  },
};
</script>

<style scoped>
.border-b {
  border-bottom: 1px solid #d8d8d8;
}

.error-outline {
  border: 1px solid var(--v-error-base);
}

.v-error {
  color: rgb(204, 0, 0);
  opacity: 1 !important;
}
</style>
