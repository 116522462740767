<template>
  <div class="announcement-wrapper" data-test="announcementWrapper">
    <banner-child
      v-for="(notification, index) in getRelevantNotifications"
      :key="index"
      :enabled="notification.enabled"
      m
      :alert-type="notification.type"
      :current-view="view"
      :messages="notification.messages"
    ></banner-child>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import BannerChild from "@/components/BannerChild.vue";
import { isBannerGetExpired } from "@/utils/payout";
// import mockData from "./mockbanner.json"
export default {
  name: "AnnouncementBannerWrapper",
  components: {
    BannerChild,
  },
  props: {
    maxBanners: { type: Number, required: false, default: 0 },
    injectedView: { type: String, required: false, default: "" },
  },
  setup() {
    // make sure to add props back in
    return {
      authStore: useAuthStore(),
      getNotificationData: function () {
        const notificationData = this.authStore.getNotificationData; // this.mockData
        this.notifications = notificationData?.notifications.filter(
          (notification) =>
            !isBannerGetExpired(notification.startDate, notification.expiration)
        );

        if (!this.notifications) this.notifications = [];
      },
    };
  },
  data() {
    return {
      notifications: [],
      view: "",
      //mockData:mockData,
    };
  },
  computed: {
    getRelevantNotifications: function () {
      return this.notifications.filter(
        (notification) =>
          notification.views.includes(this.injectedView) ||
          notification.views.includes(this.view) ||
          notification.views.includes("*")
      );
    },
  },
  watch: {
    $route(newRoute) {
      this.view = newRoute.name;
    },
  },
  created() {
    this.view = this.$route && this.$route.name ? this.$route.name : "";
    this.getNotificationData();
  },
};
</script>

<style>
.banner_container {
  z-index: 100;
}
</style>
