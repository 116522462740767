<template>
  <div
    class="font-weight-regular primary--text"
    data-test="new-payout-type-name"
    :class="nameAlign"
  >
    {{ name }}
  </div>
</template>

<script>
export default {
  name: "NewPayoutMethodCardName",

  props: {
    name: { type: String, required: true },
  },
  computed: {
    nameAlign() {
      if (this.name === "Debit Card") {
        return "alignDebit";
      } else if (this.name === "eCheck") {
        return "alignEcheck";
      } else if (this.name === "Crypto Wallet") {
        return "alignCoinBase";
      } else if (this.name === "Prepaid Card") {
        return "alignMoneyNW";
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.alignDebit {
  margin-top: -2px;
}
.alignEcheck {
  margin-top: -8px;
}
.alignCoinBase {
  margin-top: -5px;
}

.alignMoneyNW {
  margin-top: -2px;
}
</style>
