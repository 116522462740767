<script>
import AppTextField from "@/components/forms/AppTextField";
import { useVModel } from "@vueuse/core";
import { required, inputPattern } from "@/utils/rules";
import { i18n } from "@/plugins/i18n";

export default {
  name: "ZipCodeField",

  components: { AppTextField },

  inheritAttrs: false,

  props: {
    value: { type: String, default: null },
    label: { type: [String, Object], default: "" },
    placeholder: { type: String, default: "" },
  },

  setup(props, { emit }) {
    const rules = [
      required(i18n.global.t("ZipCodeField.required")),
      inputPattern(
        /^[0-9]{5}(?:-[0-9]{4})?$/,
        i18n.global.t("ZipCodeField.inputPattern")
      ),
    ];

    const modelValue = useVModel(props, "value", emit, { eventName: "input" });
    return { modelValue, rules };
  },
};
</script>

<template>
  <AppTextField
    v-model="modelValue"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    v-bind="$attrs"
    data-test="zip-code"
  />
</template>
