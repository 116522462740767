<template>
  <v-container
    data-test="auth-img-container"
    class="text-white d-flex flex-column justify-space-between flex-md-row h-full pb-md-16"
    :class="containerBackground"
  >
    <!-- <div class="pl-2 pt-2" data-test="auth-container-brand-sm-down">
      <carat-logo data-test="carat-logo" class="h-7" />
    </div> -->

    <v-row class="align-end px-5">
      <v-col cols="12" md="8" offset-md="2">
        <v-divider
          class="mb-2 container-text-border"
          color="white"
          width="70%"
        />

        <div
          v-if="$route.name.includes('sign-in')"
          data-test="auth-container-sign-in-info"
        >
          {{ $t("AuthCover.signIn.info") }}
        </div>
        <div v-else>
          {{ $t("AuthCover.register.info") }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import CaratLogo from "@/assets/img/carat_logo.svg?inline";

export default {
  name: "AuthCover",

  components: {},

  computed: {
    containerBackground() {
      return this.$route.name.includes("sign-in")
        ? "sign-in-container"
        : "registration-container";
    },
  },
};
</script>

<style scoped>
.sign-in-container {
  background: center center no-repeat url("~@/assets/img/signin_cover.png");
  background-size: cover;
}

.registration-container {
  background: center center no-repeat
    url("~@/assets/img/registration_cover.png");
  background-size: cover;
}

.container-text-border {
  border-width: 1px !important;
  border-color: white !important;
}

.h-7 {
  height: 1.75rem;
}
</style>
