<script>
import { mdiCheckDecagramOutline } from "@mdi/js";
import { useDialogPanelStore } from "@/stores/dialog-panel";
import { resolveErrorMsg } from "@/utils/error-handler";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useAppStore } from "@/stores/app";
import { useAuthStore } from "@/stores/auth";
import { usePayoutStore } from "@/stores/payout";
import { useDisburse } from "@/composables/disburse";
import PayoutMethodsConfirmCancelDialog from "@/components/payout-methods/PayoutMethodsConfirmCancelDialog";
import { computed, ref } from "vue";
import { useTransientPayoutMethod } from "@/composables/transient-payout-method";
import TermsAndConditionsCheckbox from "@/components/forms/TermsAndConditionsCheckbox";
import { maskNumber } from "@/filters/filters";

export default {
  name: "MoneyNetworkSuccess",

  components: { TermsAndConditionsCheckbox, PayoutMethodsConfirmCancelDialog },

  props: {
    accountData: { type: Object, required: true },
  },

  setup(props) {
    //submit
    const appStore = useAppStore();
    const payoutStore = usePayoutStore();
    const authStore = useAuthStore();

    const { disburse, disburseLoading } = useDisburse();
    const { store } = useTransientPayoutMethod();

    const transient = computed(() => {
      return authStore.isGuest || !!payoutStore.selected;
    });
    const getPayoutSrc = computed(() => {
      return payoutStore.getPayoutTypeSelectedSource;
    });
    const form = ref(null);
    const showConfirmCancel = ref(false);
    const termsAndConditions = ref(false);
    const apiSchema = {
      recipientProfileInfo: {
        recipientId: authStore.user.recipientId,
      },
      source: "TOKEN",
      token: {
        tokenId: props.accountData.accountProfile.token.tokenId,
        tokenProvider: props.accountData.accountProfile.token.tokenProvider,
      },
    };

    const submit = async () => {
      if (form.value.validate()) {
        await store(apiSchema);
        disburse();
      }
    };

    const maskedNumber = computed(() => {
      const { accountData } = props;
      const isPrepaidOrMoneyNetwork =
        getPayoutSrc.value === "PREPAID" ||
        getPayoutSrc.value === "MONEYNETWORK";
      const isUsDebit = getPayoutSrc.value === "US_DEBIT";

      return isPrepaidOrMoneyNetwork
        ? maskNumber(accountData.accountProfile.accountNumber)
        : isUsDebit
        ? maskNumber(accountData.accountProfile.cardNumber)
        : "";
    });

    //continue
    const loading = ref(false);
    const dialogPanelStore = useDialogPanelStore();
    const payoutMethodStore = usePayoutMethodStore();

    const returnToDashboard = () => {
      loading.value = true;

      payoutMethodStore
        .fetchPersisted()
        .then(() => {
          dialogPanelStore.hide();
        })
        .catch((err) => {
          const msg = resolveErrorMsg(
            err,
            "MoneyNetworkSuccess.fetchNetworkError"
          );
          appStore.showError(msg);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      mdiCheckDecagramOutline,
      form,
      loading,
      disburseLoading,
      showConfirmCancel,
      termsAndConditions,
      transient,
      submit,
      returnToDashboard,
      getPayoutSrc,
      maskedNumber,
    };
  },
};
</script>

<template>
  <div>
    <div class="d-flex justify-center pt-10">
      <v-icon color="success" size="75" data-test="account-check-icon">
        mdi-check-decagram-outline
      </v-icon>
    </div>

    <div class="mx-md-8 mx-4">
      <h1
        v-if="getPayoutSrc === 'PREPAID' || getPayoutSrc === 'MONEYNETWORK'"
        class="pt-md-10 pt-6 text-md-h3 text-h4"
      >
        {{ $t("MoneyNetworkSuccess.successMsg") }}
      </h1>

      <h1
        v-if="getPayoutSrc === 'US_DEBIT'"
        class="pt-md-10 pt-6 text-md-h3 text-h4"
      >
        {{ $t("USDebitCardSuccess.successMsg") }}
      </h1>

      <div class="pt-md-10 pt-6" data-test="card-number-msg">
        <span
          v-if="getPayoutSrc === 'PREPAID' || getPayoutSrc === 'MONEYNETWORK'"
          >{{ $t("MoneyNetworkSuccess.lastFour") }}</span
        >
        <span v-if="getPayoutSrc === 'US_DEBIT'">{{
          $t("USDebitCardSuccess.lastFour")
        }}</span>
        <span>&nbsp;</span>
        <span>{{ maskedNumber }}</span>
      </div>

      <div class="mt-12">
        <v-form v-if="transient" ref="form">
          <i18n-t
            v-if="getPayoutSrc === 'PREPAID' || getPayoutSrc === 'MONEYNETWORK'"
            keypath="MoneyNetworkSuccess.transfer"
            tag="span"
          >
            <span class="font-weight-bold">
              {{ $t("MoneyNetworkSuccess.submit") }}
            </span>
            <span class="font-weight-bold">
              {{ $t("MoneyNetworkSuccess.cancel") }}
            </span>
          </i18n-t>

          <i18n-t
            v-if="getPayoutSrc === 'US_DEBIT'"
            keypath="USDebitCardSuccess.transfer"
            tag="span"
          >
            <span class="font-weight-bold">
              {{ $t("USDebitCardSuccess.submit") }}
            </span>
            <span class="font-weight-bold">
              {{ $t("USDebitCardSuccess.cancel") }}
            </span>
          </i18n-t>

          <terms-and-conditions-checkbox
            v-model="termsAndConditions"
            data-test="terms-and-conditions"
          />

          <div class="d-flex justify-center pt-3">
            <v-btn
              tile
              depressed
              :loading="disburseLoading"
              color="primary"
              class="mx-2 custom_min_width_100"
              data-test="submit-btn"
              @click="submit"
            >
              {{ $t("MoneyNetworkSuccess.submit") }}
            </v-btn>
            <v-btn
              tile
              variant="outlined"
              class="mx-2 custom_min_width_100"
              data-test="cancel-btn"
              @click="showConfirmCancel = true"
            >
              {{ $t("MoneyNetworkSuccess.cancel") }}
            </v-btn>
          </div>
        </v-form>
        <div v-else class="d-flex justify-center">
          <v-btn
            tile
            depressed
            variant="outlined"
            :loading="loading"
            color="primary"
            min-width="125"
            data-test="continue-btn"
            @click="returnToDashboard"
          >
            {{ $t("MoneyNetworkSuccess.continue") }}
          </v-btn>
        </div>
      </div>
    </div>

    <payout-methods-confirm-cancel-dialog v-model="showConfirmCancel" />
  </div>
</template>
