<template>
  <v-snackbar
    v-model="appStore.error.show"
    type="error"
    color="error"
    data-test="the-error-alert"
    :top="mdAndUp"
    :right="mdAndUp"
    :bottom="smAndDown"
    app
  >
    <svg-icon type="mdi" class="mr-3" :path="mdiAlert"></svg-icon>

    <span data-test="the-error-alert-message">{{ errorMessage }}</span>

    <template #action="{ attrs }">
      <v-btn
        icon
        v-bind="attrs"
        :aria-label="$t('TheErrorAlert.close')"
        :title="$t('TheErrorAlert.close')"
        @click="appStore.hideError"
      >
        <svg-icon type="mdi" :path="mdiClose"></svg-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mdiAlert, mdiClose } from "@mdi/js";
import { useAppStore } from "@/stores/app";
import { useDisplay } from "vuetify";

export default {
  name: "TheErrorAlert",

  setup() {
    const appStore = useAppStore();
    const { mdAndUp, smAndDown } = useDisplay();
    return { appStore, mdAndUp, smAndDown };
  },

  data() {
    return {
      mdiAlert,
      mdiClose,
    };
  },

  computed: {
    errorMessage() {
      return this.appStore.error.message;
    },
  },
};
</script>
