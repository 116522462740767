<template>
  <div v-if="payout" class="short-background w-full mt-10 pb-4">
    <div class="d-flex align-center flex-column">
      <merchant-logo
        :merchant="payout.merchant.groupName"
        size="95px"
        data-test="payout-details-item-logo"
        backdrop
      />
      <div class="font-weight-bold pt-5" data-test="payout-details-merchant">
        {{ payout.merchant.formattedGroupName }}
      </div>

      <div class="text-h2 text-md-h1" data-test="payout-details-amount">
        {{
          currency(
            payout.totalTransactionAmount.total,
            payout.totalTransactionAmount.currency
          )
        }}
      </div>
    </div>

    <div v-if="displayTransferredTo" class="d-flex align-center flex-column">
      <div class="font-weight-bold">
        {{ $t("PayoutSummary.transferredTo") }}:
      </div>

      <div>
        <span data-test="summary-method-type">
          {{ payoutMethodDisplayNames[recipient.source] }}
        </span>
        -
        <span data-test="summary-method-account">{{
          payoutMethodSourceData
        }}</span>
      </div>
    </div>

    <div v-else-if="pending" class="d-flex justify-center">
      <div class="d-flex mr-3 mr-sm-7">
        <div class="font-weight-bold mr-1">
          {{ $t("PayoutSummary.issued") }}:
        </div>
        <div data-test="summary-issued">
          {{ shortDate(payout.transactionDate) }}
        </div>
      </div>

      <div class="d-flex ml-3 ml-sm-7">
        <div class="font-weight-bold mr-1">
          {{ $t("PayoutSummary.expires") }}:
        </div>
        <div data-test="summary-expiration">
          {{ shortDate(payout.expiryDate) }}
        </div>
      </div>
    </div>

    <div class="d-flex justify-center mt-5">
      <recipient-status :payout="payout" />
    </div>
  </div>
</template>

<script>
import MerchantLogo from "@/components/MerchantLogo";
import RecipientStatus from "@/components/RecipientStatus";
import { payoutMethodDisplayNames } from "@/utils/payout-methods";
import { getRecipient, isPending } from "@/utils/payout";
import { isDisbursed } from "@/utils/payout";
import { currency, shortDate } from "@/filters/filters";

export default {
  name: "PayoutSummary",

  components: { RecipientStatus, MerchantLogo },

  props: {
    payout: { type: Object, default: () => null },
  },

  setup() {
    return {
      payoutMethodDisplayNames,
      isDisbursed,
      currency,
      shortDate,
    };
  },

  computed: {
    payoutMethodSourceData() {
      if (this.recipient.source === "ECHECK") {
        return this.recipient.checkNumber;
      } else {
        return this.recipient.alias;
      }
    },

    recipient() {
      return getRecipient(this.payout);
    },

    pending() {
      return isPending(this.payout);
    },

    displayTransferredTo() {
      return this.isDisbursed(this.payout) && this.recipient.source;
    },
  },
};
</script>

<style scoped>
.short-background {
  background: linear-gradient(transparent 17%, var(--v-background-base) 17%)
    no-repeat;
}
</style>
