import client from "@/api/clients/static-client";

export default {
  getMerchantLogoColorDetails(merchant) {
    return client.get(`/images/v2/${merchant}/${merchant}.json`);
  },

  getMerchantLogoImageDetails(merchant) {
    return client.get(`/images/v2/${merchant}/${merchant}.svg`);
  },

  getFaqsData(bankCode) {
    if (bankCode === "002") {
      return client.get("/docs/NYCB_frequently_asked_questions.json");
    }
    return client.get("/docs/frequently_asked_questions.json");
  },
};
