import { useDynamicContent } from "@/composables/dynamic-content";
import { ref } from "vue";
import { usePayoutMethodStore } from "@/stores/payout-method";
import MoneyNetworkSuccess from "@/components/payout-methods/money-network/MoneyNetworkSuccess";
import { resolveErrorMsg } from "@/utils/error-handler";
import { useAppStore } from "@/stores/app";
import MoneyNetworkChallengeQuestions from "@/components/payout-methods/money-network/MoneyNetworkChallengeQuestions";
import get from "lodash/get";
import MoneyNetworkEnrollDeclined from "@/components/payout-methods/money-network/MoneyNetworkEnrollDeclined";

export function useMoneyNetworkEnrollment() {
  const { setContent } = useDynamicContent();
  const enrollLoading = ref(false);

  const enrollment = (apiSchema, enrollmentData) =>
    handleEnrollment(apiSchema, enrollmentData, enrollLoading, setContent);

  return {
    enrollLoading,
    enrollment,
  };
}

async function handleEnrollment(
  apiSchema,
  enrollmentData,
  enrollLoading,
  setContent
) {
  enrollLoading.value = true;
  const payoutMethodStore = usePayoutMethodStore();
  const appStore = useAppStore();

  payoutMethodStore
    .enrollMoneyNetwork(apiSchema, enrollmentData)
    .then((res) => {
      if (res.data.idCheckStatus === "PARTIAL") {
        setContent({
          component: MoneyNetworkChallengeQuestions,
          attrs: {
            enrollmentData: enrollmentData,
            questions: res.data.idCheckQuestions.questions,
            idCheckNumber: res.data.idCheckQuestions.idCheckNumber,
            apiSchema: apiSchema,
          },
        });
      } else {
        setContent({
          component: MoneyNetworkSuccess,
          attrs: { accountData: res.data },
        });
      }
    })
    .catch((err) => {
      const code = get(err, "response.data.code");
      const declinedCodes = ["400303", "400304", "400305"];
      if (declinedCodes.includes(code)) {
        setContent(MoneyNetworkEnrollDeclined);
        return;
      }

      const msg = resolveErrorMsg(err, "NewMoneyNetworkForm");
      appStore.showError(msg);
    })
    .finally(() => {
      enrollLoading.value = false;
    });
}
