<template>
  <div class="pa-3">
    <v-row>
      <v-col cols="12" sm="6" style="display: none">
        <v-select
          v-model="show.value"
          :items="show.items"
          item-title="text"
          item-value="value"
          class="mr-md-4"
          data-test="crypto-wallet-method-list"
          :aria-label="$t('CoinBaseForm.cryptoBasedmethods')"
        />
      </v-col>
    </v-row>
    <div class="my-7 d-flex align-center flex-column">
      <coin-base-form />
    </div>
  </div>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import { useDynamicContent } from "@/composables/dynamic-content";
import CoinBaseForm from "@/components/payout-methods/crypto-wallet/coin-base/CoinBaseForm";
import { ref } from "vue";
import { useTransientPayoutMethod } from "@/composables/transient-payout-method";

export default {
  name: "CryptoWalletContinueNew",

  components: {
    CoinBaseForm,
  },

  setup() {
    const { setContent } = useDynamicContent();
    useTransientPayoutMethod();

    const show = ref({
      items: [
        {
          text: i18n.global.t("NewPayoutMethods.cryptowallet.coinbase.name"),
          value: "coinbase",
        },
        {
          text: i18n.global.t("NewPayoutMethods.cryptowallet.bakkt.name"),
          value: "bakkt",
        },
        {
          text: i18n.global.t("NewPayoutMethods.cryptowallet.ftx.name"),
          value: "ftx",
        },
      ],
      value: "coinbase",
    });

    return {
      setContent,
      show,
    };
  },
};
</script>
