<template>
  <a
    :href="termsOfUseUrl"
    class="text-decoration-underline"
    data-test="link-to-terms-conditions"
    target="_blank"
  >
    {{ $t("TheFooter.terms") }}
  </a>
</template>

<script>
import { usePayoutStore } from "@/stores/payout";
import { useAuthStore } from "@/stores/auth";
export default {
  name: "TermsOfUse",

  props: {
    payoutMethodType: {
      type: String,
      default: "",
    },
  },

  setup() {
    const payoutStore = usePayoutStore();
    const authStore = useAuthStore();
    return {
      payoutStore,
      authStore,
    };
  },

  computed: {
    termsOfUseUrl() {
      if (this.payoutStore?.portalConfigurations?.TERMS_OF_USE !== undefined) {
        return this.payoutStore?.portalConfigurations?.TERMS_OF_USE;
      } else if (
        this.payoutStore?.portalConfigurations?.TERMS_OF_USE === undefined &&
        !this.authStore.isGuest
      ) {
        return (
          process.env.VUE_APP_DDP_ASSETS_URL + "/docs/portal-payee-terms.pdf"
        );
      } else {
        return (
          process.env.VUE_APP_DDP_ASSETS_URL +
          "/docs/Flagstar_Portal_Payee_Terms.pdf"
        );
      }
    },
  },
};
</script>
