<template>
  <div>
    <div class="d-sr-only">
      <div>{{ $t("PasswordField.ariaTitle") }}</div>
      <ol>
        <li>{{ $t("PasswordField.charMin") }}</li>
        <li>{{ $t("PasswordField.number") }}</li>
        <li>{{ $t("PasswordField.uppercase") }}</li>
        <li>{{ $t("PasswordField.lowercase") }}</li>
        <li>{{ $t("PasswordField.ariaSpecial") }}</li>
        <li>{{ $t("PasswordField.consecutive") }}</li>
      </ol>
    </div>
    <div ref="anchor" style="position: relative; top: 50px" aria-hidden="true">
      <v-menu
        v-model="showTooltip"
        :attach="$refs.anchor"
        :close-on-content-click="false"
        :close-on-click="false"
        tile
        role="textbox"
      >
        <v-sheet class="pa-4" width="255">
          <div class="font-weight-bold mb-2">
            {{ $t("PasswordField.title") }}
          </div>

          <password-requirement
            :text="$t('PasswordField.charMin')"
            :requirement-met="characterLimit"
          />
          <password-requirement
            :text="$t('PasswordField.number')"
            :requirement-met="hasNumber"
          />
          <password-requirement
            :text="$t('PasswordField.uppercase')"
            :requirement-met="uppercase"
          />
          <password-requirement
            :text="$t('PasswordField.lowercase')"
            :requirement-met="lowercase"
          />
          <password-requirement
            :text="$t('PasswordField.special')"
            :requirement-met="specialCharacter"
          />
          <password-requirement
            :text="$t('PasswordField.consecutive')"
            :requirement-met="noMoreThanTwoConsecutive"
          />
          <password-requirement
            :text="$t('PasswordField.pwNoSpace')"
            :requirement-met="noWhiteSpace"
          />
        </v-sheet>
      </v-menu>
    </div>

    <secure-text-field
      v-model="pw"
      :label="$t('PasswordField.pw')"
      :rules="[
        required(),
        uppercase || $t('PasswordField.pwContainsUppercase'),
        characterLimit || $t('PasswordField.pwLength'),
        hasNumber || $t('PasswordField.pwContainsNumber'),
        specialCharacter || $t('PasswordField.pwContainsSpecial'),
        lowercase || $t('PasswordField.pwContainsLowercase'),
        noMoreThanTwoConsecutive || $t('PasswordField.pwNonConsecutive'),
        noWhiteSpace || $t('PasswordField.pwNoSpace'),
      ]"
      data-test="pw-field"
      aria-required="true"
      @focusin="showTooltip = true"
      @focusout="showTooltip = false"
    />
  </div>
</template>

<script>
import { required } from "@/utils/rules";
import PasswordRequirement from "@/components/PasswordRequirement";
import SecureTextField from "@/components/forms/SecureTextField";
import { computed } from "vue";

export default {
  name: "PasswordField",

  components: { SecureTextField, PasswordRequirement },

  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const pw = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        emit("update:modelValue", newValue);
      },
    });
    return { required, pw };
  },

  data() {
    return {
      showTooltip: false,
    };
  },

  computed: {
    characterLimit() {
      return this.pw.length >= 9 && this.pw.length <= 20;
    },
    hasNumber() {
      return /\d/.test(this.pw);
    },
    uppercase() {
      return /[A-Z]/.test(this.pw);
    },
    lowercase() {
      return /[a-z]/.test(this.pw);
    },
    specialCharacter() {
      return /[@()!#$%^&*+=\-"]/g.test(this.pw);
    },
    noMoreThanTwoConsecutive() {
      return !/(.)(\1{2,})/g.test(this.pw);
    },
    noWhiteSpace() {
      return /^\S+$/.test(this.pw);
    },
  },
};
</script>

<style scoped>
.v-menu__content {
  max-width: unset;
}
</style>
