import axios from "axios";
import { auth, requestId, traceId } from "@/api/clients/middleware"; //

const http = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API_BASE_URL,
  headers: { "x-api-key": process.env.VUE_APP_AUTH_API_KEY },
  withCredentials: true,
});

http.interceptors.request.use(requestId);
http.interceptors.request.use(auth);
http.interceptors.request.use(traceId);

export default http;
