<template>
  <div class="pa-3">
    <confirm-dialog
      v-model="showTermsDialog"
      :title="$t('PaypalForm.acceptTermsHeader')"
      :show-cancel-button="false"
      :confirm-button-text="$t('PaypalForm.acceptTermsContinue')"
      @input:confirm="showTermsDialog = false"
    >
      <i18n-t keypath="PaypalForm.acceptTermsMessage" tag="span">
        <a
          class="fiserv-dark-blue--text text-decoration-none"
          href="#"
          @click.prevent="navigateTofaq"
          >{{ $t("PaypalForm.here") }}</a
        >
      </i18n-t>
    </confirm-dialog>

    <div class="my-8 text-center" data-test="header">
      <div class="primary--text">
        {{ $t("PaypalForm.note") }}
      </div>

      <read-more-tooltip bold>
        <!--{{ $t("PaypalForm.tooltipContent") }}-->

        {{ getTooltipTimeFrame("PAYPAL", "") }}
      </read-more-tooltip>

      <v-divider class="mt-4 mb-4" />

      <div class="primary--text text-left font-weight-bold">
        {{ $t("PaypalForm.extraNote") }}
      </div>
    </div>

    <v-form ref="form" v-model="isFormValid" :disabled="disableForm">
      <v-row>
        <v-col cols="12" class="pr-md-4">
          <app-text-field
            v-model.trim="form.email"
            type="email"
            data-test="email-input"
            autocomplete="off"
            aria-autocomplete="none"
            :label="$t('PaypalForm.email')"
            :rules="[required($t('PaypalForm.emailRequired')), email()]"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pr-md-4">
          <app-text-field
            v-model.trim="form.confirmEmail"
            type="email"
            data-test="confirm-email-input"
            :label="$t('PaypalForm.confirmEmail')"
            autocomplete="off"
            aria-autocomplete="none"
            :rules="[
              required($t('PaypalForm.emailRequired')),
              email(),
              emailsMatch,
            ]"
            @paste.prevent
          />
        </v-col>
      </v-row>

      <terms-and-conditions-checkbox
        v-model="form.terms"
        data-test="accept-terms"
        payout-method-type="PAYPAL"
        class="payoutTermsCondition"
      />

      <div class="d-flex justify-center">
        <v-btn
          class="mr-4 mr-md-6 custom_min_width_100"
          tile
          :disabled="disburseLoading || disableForm"
          elevation="0"
          color="primary"
          data-test="add-paypal"
          :loading="disburseLoading"
          @click="submit"
          >{{ $t("PaypalForm.submit") }}
        </v-btn>

        <v-btn
          class="ml-4 ml-md-6 custom_min_width_100"
          tile
          variant="outlined"
          color="primary"
          data-test="cancel"
          @click="showConfirmCancel = true"
          >{{ $t("PaypalForm.cancel") }}</v-btn
        >
      </div>

      <div v-if="disableForm" class="d-flex px-3 pt-6 px-sm-4">
        <v-alert type="error" tile>
          <span data-test="disburse-error-message">
            {{ disburseError }}
          </span>
        </v-alert>
      </div>
    </v-form>

    <payout-methods-confirm-cancel-dialog v-model="showConfirmCancel" />
  </div>
</template>

<script>
import { mapState } from "pinia";
import PayoutMethodsConfirmCancelDialog from "@/components/payout-methods/PayoutMethodsConfirmCancelDialog";
import { mdiArrowTopRight } from "@mdi/js";
import AppTextField from "@/components/forms/AppTextField";
import isEqual from "lodash/isEqual";
import TermsAndConditionsCheckbox from "@/components/forms/TermsAndConditionsCheckbox";
import { useAuthStore } from "@/stores/auth";
import { i18n } from "@/plugins/i18n";
import { useTitle } from "@/composables/title";
import { email, required } from "@/utils/rules";
import { getTooltipTimeFrame } from "@/utils/payout";
import { useDisburse } from "@/composables/disburse";
import { usePayoutMethodStore } from "@/stores/payout-method";
import ReadMoreTooltip from "@/components/payout-methods/ReadMoreTooltip";
import { useDynamicContent } from "@/composables/dynamic-content";
import { useBack } from "@/composables/back";
import NewPayoutMethods from "@/components/payout-methods/NewPayoutMethods";
import ConfirmDialog from "@/components/ConfirmDialog";
import { usePayoutStore } from "@/stores/payout";

export default {
  name: "PaypalForm",

  components: {
    ReadMoreTooltip,
    AppTextField,
    PayoutMethodsConfirmCancelDialog,
    TermsAndConditionsCheckbox,
    ConfirmDialog,
  },

  setup() {
    const authStore = useAuthStore();
    const payoutStore = usePayoutStore();
    const title = authStore.isGuest
      ? i18n.global.t("PaypalForm.addPaypal")
      : i18n.global.t("PaypalForm.paypal");
    useTitle(title);

    if (authStore.isGuest) {
      const { setContent } = useDynamicContent();
      useBack(() => setContent(NewPayoutMethods));
    }

    const {
      disburse,
      disburseLoading,
      error: disburseError,
      disabled: transferDisabled,
    } = useDisburse();

    const payoutMethodStore = usePayoutMethodStore();

    return {
      disburse,
      disburseLoading,
      disburseError,
      transferDisabled,
      authStore,
      payoutMethodStore,
      payoutStore,
      required,
      email,
      getTooltipTimeFrame,
    };
  },

  data() {
    return {
      mdiArrowTopRight,
      showConfirmCancel: false,
      isFormValid: false,
      showTermsDialog: false,
      form: {
        email: "",
        confirmEmail: "",
        mobile: "",
        confirmMobile: "",
        terms: false,
      },
    };
  },

  computed: {
    ...mapState(useAuthStore, ["user", "isUser"]),

    emailsMatch() {
      return (
        isEqual(
          this.form.email.toLowerCase(),
          this.form.confirmEmail.toLowerCase()
        ) || i18n.global.t("PaypalForm.emailDoesNotMatch")
      );
    },

    disableForm() {
      return this.transferDisabled;
    },
    getBankCode() {
      return this.authStore.retriveBankCode;
    },
  },

  created() {
    if (this.authStore.isGuest && this.getBankCode === "002")
      this.showTermsDialog = true;

    this.form.email = this.user && this.user.emailAddress.value;
  },

  methods: {
    navigateTofaq() {
      this.payoutStore.setPaypalgovtLink(true);
      this.showTermsDialog = false;
      this.$router.push({ name: "faq" });
    },
    apiSchema() {
      return {
        recipientProfileInfo: { recipientId: this.user.recipientId },
        source: "PAYPAL",
        paypal: { email: { value: this.form.email } },
      };
    },

    async submit() {
      const valid = await this.$refs.form.validate();
      if (!valid.valid) return;

      this.payoutMethodStore.selected = this.apiSchema();
      this.disburse();
    },
  },
};
</script>
