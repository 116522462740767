<template>
  <app-text-field
    v-model="modelValue"
    v-maska:[options]
    type="tel"
    :label="label"
    v-bind="$attrs"
    :rules="combinedRules"
    autocomplete="tel"
  />
</template>

<script>
import AppTextField from "@/components/forms/AppTextField";
import { minLength, nonZero } from "@/utils/rules";
import { useVModel } from "@vueuse/core";
import { i18n } from "@/plugins/i18n";

export default {
  name: "PhoneNumberField",

  components: { AppTextField },

  inheritAttrs: false,

  props: {
    value: { type: String, default: null },
    label: { type: [String, Object], default: "" },
    rules: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    const modelValue = useVModel(props, "value", emit, { eventName: "input" });
    const options = { mask: "###-###-####" };
    return {
      nonZero,
      minLength,
      modelValue,
      options,
    };
  },

  data() {
    return {
      baseRules: [
        this.nonZero(i18n.global.t("PhoneNumberField.validPhoneNumber")),
        this.minLength(12, i18n.global.t("PhoneNumberField.phoneNumberLength")),
      ],
    };
  },

  computed: {
    combinedRules() {
      return [...this.rules, ...this.baseRules];
    },
  },
};
</script>
