<template>
  <v-dialog
    id="hide_overlay_dialog"
    v-model="dialogPanelStore.visible"
    transition="slide-x-reverse-transition"
    hide-overlay
    persistent
    no-click-animation
    fullscreen
    content-class="dialog-display-right"
    attach="main"
    :retain-focus="false"
  >
    <v-card>
      <focus-loop :is-visible="dialogPanelStore.visible" :auto-focus="false">
        <v-card-title>
          <v-card-actions>
            <div
              ref="title"
              class="font-weight-bold"
              data-test="dialog-title"
              tabindex="-1"
              role="heading"
              aria-level="1"
            >
              {{ title }}
            </div>

            <v-spacer />

            <v-btn
              color="primary"
              text
              icon
              :title="titleClose"
              :aria-label="titleClose"
              data-test="dialog-close"
              @click="close"
            >
              <svg-icon
                type="mdi"
                :path="mdiClose"
                color="primary"
                size="30"
              ></svg-icon>
            </v-btn>
          </v-card-actions>
        </v-card-title>

        <v-divider class="mx-6 mt-4" />
        <!-- Code block related to banner-->
        <div class="px-6 py-4">
          <portal-target name="userPayoutStatus" />

          <dynamic-content v-model="content" :provider="setContent" />
        </div>
      </focus-loop>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";
import DynamicContent from "@/components/DynamicContent";
import { usePayoutStore } from "@/stores/payout";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useDialogPanelStore } from "@/stores/dialog-panel";
import { i18n } from "@/plugins/i18n";
import { onMounted } from "vue";

export default {
  name: "DialogPanel",

  components: { DynamicContent },

  provide() {
    const self = this;
    return { setTitle: (v) => (self.title = v) };
  },

  props: {
    scrollableBackground: { type: Boolean, default: false },
  },

  setup() {
    const dialogPanelStore = useDialogPanelStore();
    const payoutStore = usePayoutStore();
    const payoutMethodStore = usePayoutMethodStore();
    onMounted(() => {});

    return {
      dialogPanelStore,
      payoutStore,
      payoutMethodStore,
    };
  },
  data() {
    return {
      mdiClose,
      titleClose: i18n.global.t("DialogPanel.close"),
      title: "",
    };
  },

  computed: {
    visible() {
      return this.dialogPanelStore.visible;
    },
    content: {
      get() {
        return this.dialogPanelStore.content;
      },

      set(v) {
        this.dialogPanelStore.content = v;
      },
    },
  },

  watch: {
    visible(v) {
      if (this.scrollableBackground && v) {
        this.$nextTick(() => {
          document.querySelector("html").classList.remove("overflow-y-hidden");

          setTimeout(() => {
            const element = document.getElementsByTagName("html")[0];
            const attr = element.getAttributeNode("class");
            element.removeAttributeNode(attr);
          });
        });
      }
      if (v) {
        this.$nextTick(() => {
          const nodeRef = document.querySelector(".v-dialog__content");
          if (nodeRef) nodeRef.setAttribute("role", "dialog");
        });
      }
    },

    content() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          if (this.$refs.title) {
            this.$refs.title.focus();
          }
        });
      });
    },
  },

  methods: {
    close() {
      this.dialogPanelStore.hide();
      this.payoutStore.securityCodeRequired = false;
      this.payoutStore.disableCardExpiry = false;
      this.payoutStore.selected = null;
      this.payoutMethodStore.selected = null;
      this.payoutStore.savePayoutAccount = "false";
      this.payoutStore.payoutTypeSelectedSource = "";
    },

    setContent(v) {
      this.content = v;
    },
  },
};
</script>

<style>
/* This is to override the full width dialog styles*/
.dialog-display-right {
  position: relative !important;
  margin-left: auto !important;
  width: var(--dialog-panel-width) !important;
}

@media (min-width: 961px) {
  .dialog-display-right {
    --dialog-panel-width: 42.5%;
  }
}

@media (max-width: 960px) {
  .dialog-display-right {
    --dialog-panel-width: 100%;
  }
}
</style>
