<template>
  <div class="d-flex flex-column">
    <MoneyNetworkLogo />
    <v-form ref="form" class="py-6">
      <div
        class="d-flex py-6 px-3 px-sm-4 font-weight-bold"
        data-test="challenge-questions-header"
      >
        {{
          $t("MoneyNetworkForm.challengeQuestionsForm.challengeQuestionsHeader")
        }}
      </div>

      <v-row
        v-for="(question, index) in questions"
        :key="index"
        class="px-3 px-sm-4"
      >
        <v-col cols="12" sm="6" data-test="questions-placeholder">
          {{ question.prompt }}
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            v-model="challengeAnswerSelected[index]"
            :items="question.answer"
            :label="
              $t('MoneyNetworkForm.challengeQuestionsForm.challengeAnswerLabel')
            "
            :rules="[required()]"
            data-test="answer-v-select"
          />
        </v-col>
      </v-row>

      <div
        class="d-flex pt-5 px-3 px-sm-4 font-weight-bold"
        data-test="challenge-questions-note"
      >
        {{ $t("MoneyNetworkForm.challengeQuestionsForm.note") }}
      </div>

      <div class="d-flex px-3 px-sm-4">
        <v-checkbox
          v-model="acceptTermsAndConditions"
          :rules="[required()]"
          data-test="accept-terms-conditions-checkbox"
        >
          <template #label>
            <div @click.stop>
              <i18n-t
                keypath="NewMoneyNetworkForm.termsAndConditions"
                tag="div"
              >
                <a
                  :href="links[0]"
                  class="text-decoration-underline fiserv-dark-blue--text"
                  target="_blank"
                >
                  {{ $t("NewMoneyNetworkForm.accountHolderAgreement") }}
                </a>

                <a
                  :href="links[2]"
                  class="text-decoration-underline fiserv-dark-blue--text"
                  target="_blank"
                >
                  {{ $t("NewMoneyNetworkForm.feeAndTransactionLimit") }}
                </a>
                <a
                  :href="links[1]"
                  class="text-decoration-underline fiserv-dark-blue--text"
                  target="_blank"
                >
                  {{ $t("NewMoneyNetworkForm.privacyPolicy") }}
                </a>
              </i18n-t>
            </div>
          </template>
        </v-checkbox>
      </div>

      <div class="d-flex px-3 px-sm-4">
        <v-checkbox
          v-model="acceptElectronicDisclosures"
          :rules="[required()]"
          data-test="electronic-disclosures-checkbox"
        >
          <template #label>
            <div>
              {{ $t("NewMoneyNetworkForm.noticesAndDisclosures") }}
            </div>
          </template>
        </v-checkbox>
      </div>

      <div
        class="d-flex pt-3 px-3 px-sm-4 font-weight-bold"
        data-test="challenge-questions-important-info-header"
      >
        {{ $t("MoneyNetworkForm.challengeQuestionsForm.importantInfoHeader") }}
      </div>

      <div
        class="d-flex pt-3 px-3 px-sm-4"
        data-test="challenge-questions-important-info"
      >
        {{ $t("MoneyNetworkForm.challengeQuestionsForm.importantInfo") }}
      </div>

      <div class="d-flex justify-center align-center pt-10">
        <v-btn
          depressed
          tile
          color="primary"
          class="mx-2"
          data-test="challenge-questions-enroll"
          :loading="enrollLoading"
          @click="enroll"
        >
          {{ $t("MoneyNetworkForm.challengeQuestionsForm.enroll") }}
        </v-btn>
        <v-btn
          variant="outlined"
          tile
          class="mx-2"
          data-test="challenge-questions-cancel"
          @click="showConfirmCancel = true"
        >
          {{ $t("MoneyNetworkForm.challengeQuestionsForm.cancel") }}
        </v-btn>
      </div>
    </v-form>
    <PayoutMethodsConfirmCancelDialog v-model="showConfirmCancel" />
  </div>
</template>

<script>
import { required } from "@/utils/rules";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import MoneyNetworkLogo from "@/components/payout-methods/money-network/MoneyNetworkLogo";
import PayoutMethodsConfirmCancelDialog from "@/components/payout-methods/PayoutMethodsConfirmCancelDialog";
import { useMoneyNetworkEnrollment } from "@/composables/money-network-enrollment-method";

export default {
  name: "MoneyNetworkChallengeQuestions",

  components: {
    MoneyNetworkLogo,
    PayoutMethodsConfirmCancelDialog,
  },

  props: {
    questions: {
      type: Array,
      required: true,
    },
    enrollmentData: {
      type: Object,
      required: true,
    },
    idCheckNumber: {
      type: String,
      required: true,
    },
    apiSchema: {
      type: Object,
      required: true,
    },
  },

  setup() {
    useTitle(i18n.global.t("MoneyNetworkForm.challengeQuestionsForm.title"));
    const { enrollLoading, enrollment } = useMoneyNetworkEnrollment();

    return {
      required,
      enrollLoading,
      enrollment,
    };
  },

  data() {
    return {
      challengeAnswerSelected: [],
      acceptTermsAndConditions: false,
      acceptElectronicDisclosures: false,
      showConfirmCancel: false,
      links: [],
    };
  },

  created() {
    this.getTermsAndConditionsLinks();
  },

  methods: {
    getTermsAndConditionsLinks() {
      this.enrollmentData.links.forEach((link) => {
        this.links.push(link.value);
      });
    },

    getIdCheckQuestionsDetails() {
      let idCheckQuestionsList = [];
      this.questions.forEach((question, index) => {
        idCheckQuestionsList.push({
          key: question.key,
          value: this.challengeAnswerSelected[index],
        });
      });

      return idCheckQuestionsList;
    },

    createApiSchema() {
      let apiSchema = this.apiSchema;
      apiSchema["idCheckRequest"] = {
        idCheckNumber: this.idCheckNumber,
        idCheckQuestions: this.getIdCheckQuestionsDetails(),
      };
      return apiSchema;
    },

    enroll() {
      if (!this.$refs.form.validate()) return;

      const apiSchema = this.createApiSchema();
      this.enrollment(apiSchema, this.enrollmentData);
    },
  },
};
</script>
