import { format, parse } from "date-fns";
import { i18n } from "@/plugins/i18n";

export function currency(value, currency = "USD") {
  if (typeof value !== "number") {
    throw "argument must be of type Number";
  }

  const formatter = new Intl.NumberFormat([i18n.global.locale, "en-US"], {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  });

  return formatter.format(value);
}

export function longDate(value) {
  try {
    const parsed = parse(value, "MM/dd/yyyy", new Date());
    const longFmt = String(i18n.global.t("dateFormat.long"));
    return format(parsed, longFmt);
  } catch (err) {
    return value;
  }
}

export function shortDate(value) {
  try {
    const parsed = parse(value, "MM/dd/yyyy", new Date());
    const shortFmt = String(i18n.global.t("dateFormat.short"));
    return format(parsed, shortFmt);
  } catch (err) {
    return value;
  }
}

export function capitalize(value) {
  if (!value) {
    return "";
  }

  const words = value.split(" ");
  const capitalizedWords = [];

  words.forEach((word) => {
    const first = word.charAt(0).toUpperCase();
    const rest = word.slice(1).toLowerCase();
    capitalizedWords.push(`${first}${rest}`);
  });

  return capitalizedWords.join(" ");
}

export function maskNumber(number) {
  const lastFour = number.slice(-4);
  const firstFour = "\u25CF".repeat(4);
  return `${firstFour}${lastFour}`;
}

export function maskEmailId(email) {
  const [name, domain] = email.split("@");
  const maskedName = name.substr(0, 3) + "*****";
  const maskedEmail = maskedName + "@" + domain;
  return maskedEmail;
}

export function lastFourDigits(number) {
  return number.slice(-4);
}

export function phoneNumber(number) {
  //gets rid of non-numeric characters
  const cleaned = ("" + number).replace(/\D/g, "");
  //looks for the pattern (+x | +xx | '') xxx-xxx-xxxx
  const match = cleaned.match(/^(\d|\d{2}|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1];
    if (intlCode) {
      return ["+", intlCode, " ", match[2], "-", match[3], "-", match[4]].join(
        ""
      );
    } else {
      return [match[2], "-", match[3], "-", match[4]].join("");
    }
  }
  return number;
}
