<script>
import { VTextField } from "vuetify/lib/components";
import { i18n } from "@/plugins/i18n";
import { computed } from "vue";

export default {
  extends: VTextField,

  props: {
    modelValue: { type: String, default: null },
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const data = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        emit("update:modelValue", newValue);
      },
    });
    return { data };
  },

  data() {
    return {
      combineRule: [this.validChars],
    };
  },

  methods: {
    validChars(v) {
      if (!v) return true;
      return (
        /^([a-z.'\s-]+)$/i.test(v) ||
        this.$t("NameTextField.invalidNameChars", {
          label: i18n.global.t("NicknameTextField.label"),
        })
      );
    },
  },
};
</script>

<template>
  <VTextField
    v-model="data"
    maxlength="20"
    :label="$t('NicknameTextField.label')"
    data-test="nickname-text-field"
    :rules="combineRule"
  />
</template>
