<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex justify-space-around pt-8 px-4 primary--text"
      data-test="add-bank-account-info-section"
    >
      {{ $t("AchForm.info") }}
    </div>

    <v-form
      ref="form"
      v-model="isFormValid"
      :disabled="disableForm"
      class="pt-6"
    >
      <v-row v-if="!isEditing" class="px-3 px-sm-4">
        <v-col cols="12" sm="6">
          <name-text-field
            id="achFname"
            v-model="firstName"
            :label="$t('AchForm.firstName')"
            maxlength="75"
            data-test="add-bank-account-first-name"
            :disabled="isEditing"
            aria-required="true"
            autocomplete="given-name"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <name-text-field
            id="achLname"
            v-model="lastName"
            :label="$t('AchForm.lastName')"
            data-test="add-bank-account-last-name"
            :disabled="isEditing"
            aria-required="true"
            autocomplete="family-name"
          />
        </v-col>
      </v-row>

      <v-row class="px-3 px-sm-4">
        <v-col cols="12" sm="6">
          <secure-text-field
            id="achaccountNO"
            v-model.trim="accountNumber"
            :label="$t('AchForm.accountNumber')"
            :rules="isEditing ? [] : [required(), betweenNumeric(3, 17)]"
            maxlength="30"
            data-test="add-bank-account-account-number"
            :disabled="isEditing"
            aria-required="true"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <secure-text-field
            id="achaccountReverifyNO"
            v-model.trim="confirmAccountNumber"
            :label="$t('AchForm.confirmAccountNumber')"
            :rules="
              isEditing
                ? []
                : [required(), betweenNumeric(3, 17), accountNumbersMatch]
            "
            data-test="add-bank-account-confirm-account-number"
            :disabled="isEditing"
            aria-required="true"
            @paste.prevent
          />
        </v-col>
      </v-row>

      <v-row class="px-3 px-sm-4" align="end">
        <v-col cols="12" sm="6">
          <ach-classification-select v-model="classification" />
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            v-model="accountType"
            :items="accountTypeItems"
            item-title="text"
            item-value="value"
            :label="$t('AchForm.accountType')"
            :rules="[required()]"
            data-test="add-bank-account-account-type"
            :disabled="isEditing"
          />
        </v-col>
      </v-row>
      <v-row class="px-3 px-sm-4">
        <v-col cols="12" sm="6">
          <app-text-field
            id="achAccountNo"
            v-model="routingNumber.value"
            v-maska:[options]
            :label="$t('AchForm.routingNumber')"
            :rules="[required()]"
            maxlength="9"
            data-test="add-bank-account-routing-number"
            :disabled="isEditing"
            :error-messages="routingNumber.errorMessages"
            :messages="financialInstitutionName"
            :loading="financialInstitutionNameLoading"
            aria-required="true"
            @blur="validateRoutingNumber"
          >
            <template #prepend-inner>
              <v-tooltip location="top" max-width="300" :open-on-click="true">
                <template #activator="{ props }">
                  <v-btn
                    class="btn_override"
                    :aria-label="routingHelpIcon"
                    v-bind="props"
                  >
                    <svg-icon
                      class="cursor-pointer"
                      data-test="routing-number-help-icon"
                      type="mdi"
                      :path="mdiInformation"
                    >
                    </svg-icon>
                  </v-btn>
                </template>
                <div
                  class="d-flex flex-column flex-wrap align-center text-center"
                  aria-live="polite"
                >
                  <div class="pb-4 px-4">
                    {{ $t("AchForm.routingNumberToolTip") }}
                  </div>
                  <div class="d-flex align-center">
                    <v-img
                      src="@/assets/img/Blank_Check_Help.png"
                      :alt="$t('AchForm.routingNumberToolTip')"
                      width="250"
                      max-height="150"
                    ></v-img>
                  </div>

                  <div class="d-flex font-weight-bold">
                    <div
                      class="blank-check-tool-tip px-1 mr-1 ml-1 routing-account-width"
                    >
                      {{ $t("AchForm.routingOrTransitNumber") }}
                    </div>
                    <div
                      class="blank-check-tool-tip px-1 mr-1 routing-account-width"
                    >
                      {{ $t("AchForm.accountNumber") }}
                    </div>
                    <div class="blank-check-tool-tip px-1">
                      {{ $t("AchForm.checkNumber") }}
                    </div>
                  </div>
                </div>
              </v-tooltip>
            </template>
          </app-text-field>
        </v-col>

        <v-col v-if="nicknameEnabled" cols="12" sm="6">
          <nickname-text-field v-model="nickname" />
        </v-col>
      </v-row>

      <div class="d-flex pt-5 pt-sm-0 px-3 px-sm-4">
        <terms-and-conditions-checkbox
          v-model="acceptTermsAndConditions"
          data-test="add-bank-account-terms-and-conditions"
          class="payoutTermsCondition"
        />
      </div>
      <div v-if="transient" class="d-flex pt-5 pt-sm-0 px-3 px-sm-4">
        <v-checkbox
          v-if="!isGuest && isGuest != null"
          v-model="savePayoutAccount"
          class="customized_info"
          :error-messages="errorSavedPayoutMethod"
          :label="$t('PayoutsAvailable.savePayoutAccount')"
          data-test="save-payout-method"
          aria-required="true"
          :disabled="savedPayoutMethods.length > 4"
        >
        </v-checkbox>
      </div>

      <v-row v-if="savePayoutAccount" class="px-3 px-sm-4">
        <v-col cols="12" sm="6">
          <nickname-text-field v-model="nickname" />
        </v-col>
      </v-row>

      <div v-if="!transient" class="d-flex px-3 px-sm-4">
        <v-checkbox
          v-model="isPrimaryMethod"
          :label="$t('AchForm.defaultPayoutMethod')"
          :disabled="isEditing && selectedPayoutMethod.ach.default"
          data-test="add-bank-account-primary-method"
          aria-required="true"
        />
      </div>

      <div class="d-flex justify-center align-center pt-10">
        <v-btn
          id="achSubmitBtn"
          depressed
          tile
          :disabled="disburseLoading || disableForm"
          :loading="disburseLoading || persistLoading"
          color="primary"
          class="mx-2"
          :class="{
            custom_min_width_100: isEditing || transient,
            custom_min_width_150: !(isEditing || transient),
          }"
          data-test="add-bank-account-add-account"
          @click="submit"
        >
          {{
            isEditing || transient
              ? $t("AchForm.submit")
              : $t("AchForm.addAccount")
          }}
        </v-btn>
        <v-btn
          id="achCancelBtn"
          variant="outlined"
          tile
          class="mx-2 custom_min_width_100"
          data-test="add-bank-account-cancel"
          @click="showConfirmCancel = true"
        >
          {{ $t("AchForm.cancel") }}
        </v-btn>
      </div>

      <div v-if="disableForm" class="d-flex px-3 pt-6 px-sm-4">
        <v-alert type="error" tile>
          <span data-test="disburse-error-message">
            {{ disburseError }}
          </span>
        </v-alert>
      </div>
    </v-form>

    <payout-methods-confirm-cancel-dialog
      v-model="showConfirmCancel"
      :editing="isEditing"
    />
  </div>
</template>

<script>
import NicknameTextField from "@/components/forms/NicknameTextField";
import PayoutMethodsConfirmCancelDialog from "@/components/payout-methods/PayoutMethodsConfirmCancelDialog";

import { mdiAlert, mdiCheck, mdiInformation } from "@mdi/js";
import AppTextField from "@/components/forms/AppTextField";
import NameTextField from "@/components/forms/NameTextField";
import SecureTextField from "@/components/forms/SecureTextField";
import AchClassificationSelect from "@/components/forms/AchClassificationSelect";
import isEqual from "lodash/isEqual";
import TermsAndConditionsCheckbox from "@/components/forms/TermsAndConditionsCheckbox";
import { useAuthStore } from "@/stores/auth";
import { mapState } from "pinia";
import { usePayoutStore } from "@/stores/payout";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { i18n } from "@/plugins/i18n";
import { useTitle } from "@/composables/title";
import { betweenNumeric, required } from "@/utils/rules";
import { computed } from "vue";
import { usePersistPayoutMethod } from "@/composables/persist-payout-method";
import { useTransientPayoutMethod } from "@/composables/transient-payout-method";
import { useDisburse } from "@/composables/disburse";

export default {
  name: "AchForm",

  components: {
    NicknameTextField,
    AchClassificationSelect,
    SecureTextField,
    NameTextField,
    AppTextField,
    PayoutMethodsConfirmCancelDialog,
    TermsAndConditionsCheckbox,
  },

  setup() {
    const authStore = useAuthStore();
    const payoutMethodStore = usePayoutMethodStore();
    const payoutStore = usePayoutStore();

    const title = authStore.isGuest
      ? i18n.global.t("AchForm.guestTitle")
      : i18n.global.t("AchForm.bankAccount");
    useTitle(title);

    const { store } = useTransientPayoutMethod();
    const {
      disburse,
      disburseLoading,
      error: disburseError,
      disabled: transferDisabled,
    } = useDisburse();
    const { persistLoading, persist, isEditing } =
      usePersistPayoutMethod("AchForm");
    const options = isEditing.value ? { mask: "" } : { mask: "#########" };
    const onSubmit = computed(() => (payoutStore.selected ? store : persist));
    return {
      authStore,
      payoutMethodStore,
      payoutStore,
      isEditing,
      persistLoading,
      disburseLoading,
      disburse,
      disburseError,
      transferDisabled,
      onSubmit,
      required,
      betweenNumeric,
      options,
    };
  },

  data() {
    const self = this;
    return {
      mdiAlert,
      mdiCheck,
      mdiInformation,
      firstName: "",
      lastName: "",
      savePayoutAccount: false,
      financialInstitutionName: "",
      financialInstitutionNameLoading: false,
      accountType: "CHECKING",
      accountNumber: "",
      confirmAccountNumber: "",
      classification: null,
      acceptTermsAndConditions: false,
      isPrimaryMethod: false,
      accountTypeItems: [
        {
          text: self.$t("AchForm.checking"),
          value: "CHECKING",
        },
        {
          text: self.$t("AchForm.savings"),
          value: "SAVINGS",
        },
      ],
      isFormValid: false,
      routingNumber: {
        value: "",
        errorMessages: "",
      },
      nickname: "",
      showConfirmCancel: false,
      routingHelpIcon: self.$t("AchForm.routingNumberHelpIcon"),
    };
  },

  computed: {
    ...mapState(useAuthStore, ["user", "isGuest"]),
    ...mapState(usePayoutStore, { selectedPayout: "selected" }),
    ...mapState(usePayoutMethodStore, { selectedPayoutMethod: "selected" }),
    ...mapState(usePayoutMethodStore, { savedPayoutMethods: "persisted" }),

    disbursementFlowActive() {
      return !!this.selectedPayout;
    },

    errorSavedPayoutMethod() {
      const messageArray = [];
      this.savedPayoutMethods.length > 4
        ? messageArray.push(
            i18n.global.t("PayoutsAvailable.savePayoutAccountError")
          )
        : [];
      return messageArray;
    },

    accountNumbersMatch() {
      return (
        isEqual(this.accountNumber, this.confirmAccountNumber) ||
        i18n.global.t("AchForm.confirmAccountNumbers")
      );
    },

    apiSchema() {
      return {
        recipientProfileInfo: {
          recipientId: this.user.recipientId,
          firstName: this.firstName,
          lastName: this.lastName,
        },
        source: "ACH",
        label: this.nickname ? this.nickname : "",
        ach: {
          accountNumber: this.accountNumber,
          routingNumber: this.routingNumber.value,
          type: this.accountType,
          default: this.isPrimaryMethod,
          classification: this.classification,
          firstName: this.firstName,
          lastName: this.lastName,
        },
      };
    },

    transient() {
      return this.isGuest || this.disbursementFlowActive;
    },

    disableForm() {
      return this.transferDisabled;
    },

    nicknameEnabled() {
      return (
        process.env.VUE_APP_PAYOUT_METHOD_NICKNAMES_ENABLED === "true" &&
        !this.transient
      );
    },
  },

  created() {
    this.prePopulateForm();
  },

  methods: {
    prePopulateForm() {
      if (this.user) {
        this.firstName = this.user.firstName;
        this.lastName = this.user.lastName;
      }

      if (this.isEditing) {
        this.accountNumber = "********";
        this.confirmAccountNumber = "********";
        this.classification = this.selectedPayoutMethod.ach.classification;
        this.accountType = this.selectedPayoutMethod.ach.type;
        this.routingNumber.value = this.selectedPayoutMethod.ach.routingNumber;
        this.nickname = this.selectedPayoutMethod.label;
        this.isPrimaryMethod = this.selectedPayoutMethod.ach.default;

        this.fetchBank();
      }
    },

    async validateForm() {
      const valid = await this.$refs.form.validate();
      if (!valid.valid) return false;

      if (!this.isEditing) return await this.validateRoutingNumber();

      return true;
    },

    async validateRoutingNumber() {
      try {
        await this.fetchBank();
        this.routingNumber.errorMessages = "";
        return true;
      } catch (err) {
        if (err.code && err.code === "400049") {
          this.routingNumber.errorMessages = i18n.global.t(
            "AchForm.invalidRoutingNumber"
          );
        } else {
          this.routingNumber.errorMessages = i18n.global.t(
            "AchForm.networkError"
          );
        }

        this.financialInstitutionName = "";

        return false;
      }
    },

    fetchBank() {
      if (this.routingNumber.value === "") return;

      this.financialInstitutionNameLoading = true;

      return new Promise((resolve, reject) => {
        this.payoutMethodStore
          .fetchFinancialInstitutionName(this.routingNumber.value)
          .then((r) => {
            this.financialInstitutionName = r.name;
            resolve();
          })
          .catch((err) => reject(err.response.data))
          .finally(() => (this.financialInstitutionNameLoading = false));
      });
    },

    async submit() {
      const valid = await this.validateForm();
      if (!valid) return;

      await this.onSubmit(this.apiSchema);

      if (this.transient) {
        this.payoutStore.setSavePayoutAccount(
          this.savePayoutAccount ? "true" : "false"
        );
        this.disburse();
      }
    },
  },
};
</script>
<style scoped>
.blank-check-tool-tip {
  border: 1px solid #ffffff;
  border-top: none;
  font-size: 0.5rem;
}

.routing-account-width {
  width: 85px;
}
</style>
