<template>
  <div>
    <component
      :is="src"
      v-if="type === 'svg'"
      data-test="svg-logo"
      :height="svgLogoHeight"
      class="payout-method-icon-cmp"
    />

    <v-icon
      v-else
      :size="smAndUp ? 33 : 24"
      color="primary"
      data-test="new-payout-icon"
    >
      {{ src }}
    </v-icon>
  </div>
</template>

<script>
import { useDisplay } from "vuetify";
export default {
  name: "NewPayoutMethodCardIcon",

  props: {
    src: { type: null, required: true },
    type: { type: String, default: "svg" },
    methodSource: { type: String, default: "" },
  },

  setup() {
    const { smAndUp } = useDisplay();

    return { smAndUp };
  },

  computed: {
    svgLogoHeight() {
      if (this.methodSource === "ACH") {
        return this.smAndUp ? "28px" : "25px";
      } else if (this.methodSource === "PAYPAL") {
        return this.smAndUp ? "28px" : "25px";
      } else {
        return this.smAndUp ? "28px" : "20px";
      }
    },
  },
};
</script>
<style scoped>
.payout-method-icon-cmp {
  height: 32px;
  object-fit: cover;
}
</style>
