<template>
  <div class="dynamic-content" aria-live="polite" role="region">
    <component :is="content" v-bind="attrs" />
  </div>
</template>

<script>
import has from "lodash/has";

export default {
  name: "DynamicContent",

  provide() {
    const self = this;
    return {
      setContent:
        this.provider ??
        function (v) {
          self.$nextTick(() => {
            self.content = v;
          });
        },
    };
  },

  model: {
    event: "change",
  },

  props: {
    modelValue: { type: [Object, Function], default: null },
    provider: { type: Function, default: null },
  },

  emits: ["update:modelValue"],

  computed: {
    content: {
      get() {
        if (has(this.modelValue, "component")) {
          return this.modelValue.component;
        }
        return this.modelValue ?? "div";
      },

      set(v) {
        this.$emit("update:modelValue", v);
      },
    },

    attrs() {
      return has(this.modelValue, "attrs") ? this.modelValue.attrs : {};
    },
  },
};
</script>
