<template>
  <div v-if="status && status.message">
    <v-alert
      border="bottom"
      colored-border
      :type="status.color"
      elevation="2"
      max-width="600"
    >
      <span class="font-weight-bold" data-test="payout-status-title">
        {{ status.message.title }}
      </span>
      <br />
      <span class="py-2">{{ status.message.subtext }}</span>
    </v-alert>
  </div>
</template>

<script>
import {
  getOtherRecipientPaymentStatus,
  getPaymentStatus,
  getPaymentStatusDisplayConfig,
  isPending,
} from "@/utils/payout";
import { i18n } from "@/plugins/i18n";

export default {
  name: "GuestPayoutStatus",

  props: {
    payout: { type: Object, required: true },
  },

  computed: {
    status() {
      if (this.recipientsContainStatuses("DS", "DA")) {
        return {
          color: "success",
          message: {
            title: i18n.global.t("paymentStatus.claimed.message.title"),
            subtext: i18n.global.t("paymentStatus.claimed.message.subtext"),
          },
        };
      }

      if (this.recipientsContainStatuses("DS", "DP")) {
        return {
          color: "warning",
          message: {
            title: i18n.global.t(
              "paymentStatus.awaitingApproval.message.title"
            ),
            subtext: i18n.global.t(
              "paymentStatus.awaitingApproval.message.subtext"
            ),
          },
        };
      }

      if (this.recipientsContainStatuses("DS", "DI")) {
        return {
          color: "success",
          message: {
            title: i18n.global.t("paymentStatus.completed.message.title"),
            subtext: i18n.global.t("paymentStatus.completed.message.subtext"),
          },
        };
      }

      if (this.recipientsContainStatuses("DA", "DI")) {
        return {
          color: "success",
          message: {
            title: i18n.global.t("paymentStatus.completed.message.title"),
            subtext: i18n.global.t("paymentStatus.completed.message.subtext"),
          },
        };
      }

      if (this.recipientsContainStatuses("DA", "RP")) {
        return {
          color: "warning",
          message: {
            title: i18n.global.t("paymentStatus.reversalPending.message.title"),
            subtext: i18n.global.t(
              "paymentStatus.reversalPending.message.subtext"
            ),
          },
        };
      }

      if (this.recipientsContainStatuses("DA", "RS")) {
        return {
          color: "error",
          message: {
            title: i18n.global.t("paymentStatus.reversalSuccess.message.title"),
            subtext: i18n.global.t(
              "paymentStatus.reversalSuccess.message.subtext"
            ),
          },
        };
      }

      if (this.recipientsContainStatuses("DA", "RF")) {
        return {
          color: "error",
          message: {
            title: i18n.global.t("paymentStatus.reversalFailed.message.title"),
            subtext: i18n.global.t(
              "paymentStatus.reversalFailed.message.subtext"
            ),
          },
        };
      }

      if (this.recipientsContainStatuses("DA", "IP")) {
        return {
          color: "success",
          message: {
            title: i18n.global.t("paymentStatus.claimed.message.title"),
            subtext: i18n.global.t("paymentStatus.claimed.message.subtext"),
          },
        };
      }

      if (this.recipientsContainStatuses("DP", "IP")) {
        return {
          color: "warning",
          message: {
            title: i18n.global.t(
              "paymentStatus.awaitingApproval.message.title"
            ),
            subtext: i18n.global.t(
              "paymentStatus.awaitingApproval.message.subtext"
            ),
          },
        };
      }

      if (this.recipientsContainStatuses("DA", "IR")) {
        return {
          color: "success",
          message: {
            title: i18n.global.t("paymentStatus.claimed.message.title"),
            subtext: i18n.global.t("paymentStatus.claimed.message.subtext"),
          },
        };
      }

      if (this.recipientsContainStatuses("DA", "PR")) {
        return {
          color: "success",
          message: {
            title: this.$t("paymentStatus.pendingReview.message.title"),
            subtext: this.$t("paymentStatus.pendingReview.message.subtext"),
          },
        };
      }

      if (this.recipientsContainStatuses("DP", "IR")) {
        return {
          color: "warning",
          message: {
            title: i18n.global.t(
              "paymentStatus.awaitingApproval.message.title"
            ),
            subtext: i18n.global.t(
              "paymentStatus.awaitingApproval.message.subtext"
            ),
          },
        };
      }

      if (this.recipientsContainStatuses("DD", "DA")) {
        return {
          color: "warning",
          message: {
            title: i18n.global.t("paymentStatus.disbursementDeclined.label"),
            subtext: i18n.global.t(
              "paymentStatus.disbursementDeclined.subText"
            ),
          },
        };
      }

      if (this.recipientsContainStatuses("DA", "DD")) {
        return {
          color: "warning",
          message: {
            title: i18n.global.t("paymentStatus.disbursementDeclined.label"),
            subtext: i18n.global.t(
              "paymentStatus.disbursementDeclined.subText"
            ),
          },
        };
      }

      // registered/guest payout
      if (this.recipientsContainStatuses("DD")) {
        if (this.getTransactionStatus(this.payout) == "IP") {
          return {
            color: "warning",
            message: {
              title: i18n.global.t("paymentStatus.disbursementDeclined.label"),
              subtext: i18n.global.t(
                "paymentStatus.disbursementDeclined.subText"
              ),
            },
          };
        }

        if (this.getTransactionStatus(this.payout) == "IR") {
          return {
            color: "warning",
            message: {
              title: i18n.global.t("paymentStatus.disbursementDeclined.label"),
              subtext: i18n.global.t(
                "paymentStatus.disbursementDeclined.subText"
              ),
            },
          };
        }
      }

      return getPaymentStatusDisplayConfig(this.payout);
    },
  },

  methods: {
    recipientsContainStatuses(firstStatus, secondStatus) {
      const paymentStatus = getPaymentStatus(this.payout);
      const otherRecipientPaymentStatus = getOtherRecipientPaymentStatus(
        this.payout
      );

      return (
        !isPending(this.payout) &&
        (paymentStatus === firstStatus ||
          otherRecipientPaymentStatus === firstStatus) &&
        (paymentStatus === secondStatus ||
          otherRecipientPaymentStatus === secondStatus)
      );
    },
    getTransactionStatus() {
      return this.payout.transactionStatus;
    },
  },
};
</script>
