<template>
  <v-dialog v-model="show" max-width="500" :attach="main">
    <v-card>
      <v-card-title class="d-flex align-center text-h4">
        <svg-icon
          type="mdi"
          size="24"
          :path="mdiAlert"
          class="mr-2 warning"
        ></svg-icon>
        <div
          class="text-break"
          role="heading"
          aria-labelledby="confirmDialog-heading"
          aria-level="2"
          data-test="title"
        >
          {{ title }}
        </div>
      </v-card-title>

      <v-divider />

      <v-card-text class="my-5 py-0 primary--text">
        <slot>
          <span data-test="content">{{ contentText }}</span>
        </slot>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn
          tile
          depressed
          data-test="confirm-btn"
          :loading="confirming"
          class="custom_min_width_100 bg-primary"
          @click="$emit('input:confirm')"
        >
          {{ confirmButtonText }}
        </v-btn>

        <v-btn
          v-show="showCancelButton"
          tile
          variant="outlined"
          color="primary"
          data-test="cancel-btn"
          class="custom_min_width_100"
          @click="cancelButtonFn()"
        >
          {{ cancelButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiAlert } from "@mdi/js";
import { i18n } from "@/plugins/i18n";
import { computed } from "vue";

export default {
  name: "ConfirmDialog",

  props: {
    modelValue: { type: Boolean, default: false },

    confirming: { type: Boolean, default: false },

    showCancelButton: { type: Boolean, default: true },

    title: {
      type: [String, Object],
      default: function () {
        return i18n.global.t("ConfirmDialog.title");
      },
    },

    contentText: {
      type: [String, Object],
      default: function () {
        return i18n.global.t("ConfirmDialog.contentText");
      },
    },

    confirmButtonText: {
      type: [String, Object],
      default: function () {
        return i18n.global.t("ConfirmDialog.confirmButtonText");
      },
    },

    cancelButtonText: {
      type: [String, Object],
      default: function () {
        return i18n.global.t("ConfirmDialog.cancelButtonText");
      },
    },

    cancelButtonFn: {
      type: Function,
      default: function () {},
    },
  },

  emits: ["update:modelValue", "input:confirm"],

  setup(props, { emit }) {
    const show = computed({
      get() {
        return props.modelValue;
      },

      set(v) {
        emit("update:modelValue", v);
      },
    });

    const cancelButtonFn = () => {
      show.value = false;
    };

    return {
      show,
      cancelButtonFn,
    };
  },

  data() {
    return {
      mdiAlert,
    };
  },

  watch: {
    show(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          const nodeRef = document.querySelector(".v-dialog__content");
          nodeRef?.setAttribute("role", "dialog");
        });
      }
    },
  },
};
</script>
<style scoped>
.v-card > .v-card-title {
  padding: 16px 24px 10px;
}
</style>
