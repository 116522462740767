<template>
  <v-footer
    id="footer"
    color="white"
    padless
    class="main-footer black--text"
    :class="{ 'pb-82': xs }"
  >
    <v-container>
      <v-row :class="disclaimerClass">
        <v-col class="text-left">
          <ul class="pl-0">
            <li>
              <privacy-policy
                :bankcode="bankcode"
                class="text-decoration-underline fiserv-dark-blue--text"
              />
            </li>
            <li v-if="copyrightBanCorp">
              <a
                :href="copyrightBanCorpUrl"
                class="text-decoration-underline fiserv-dark-blue--text"
                target="_blank"
              >
                {{ $t("TheFooter.copyright_bancorp") }}
              </a>
            </li>
            <li
              v-if="!isErrorInRoute || authStore.isUser"
              data-test="watermark"
            >
              © {{ new Date().getFullYear() }}
              {{ copyright }}
            </li>
            <li v-if="showDisclaimer">
              {{ $t("UsDebitCardForm.disclaimerText") }}
            </li>
          </ul>
          <!-- <div> If approved, we can add this in to remove the ul and clean the footer up a bit.
            <privacy-policy :bankcode="bankcode" />
            <a
              v-if="copyrightBanCorp"
              :href="copyrightBanCorpUrl"
              class="text-decoration-none"
              target="_blank"
            >
              {{ $t("TheFooter.copyright_bancorp") }}
            </a>
            <span data-test="watermark">
              © {{ new Date().getFullYear() }}
              {{ copyright }}
            </span>
            <span v-if="showDisclaimer">
              {{ $t("UsDebitCardForm.disclaimerText") }}
            </span>
          </div> -->
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import privacyPolicy from "@/components/legal/privacyPolicy";
import { usePayoutStore } from "@/stores/payout";
import { useAuthStore } from "@/stores/auth";
import { i18n } from "@/plugins/i18n";
import copyrights from "@/utils/copyright";
import { useDisplay } from "vuetify";

export default {
  name: "TheFooter",

  components: { privacyPolicy },

  setup() {
    const payoutStore = usePayoutStore();
    const authStore = useAuthStore();
    const { xs } = useDisplay();
    return {
      payoutStore,
      authStore,
      xs,
    };
  },

  computed: {
    bankcode() {
      return (
        this.authStore?.retriveBankCode ||
        this.payoutStore?.portalConfigurations?.BANK_CODE
      );
    },
    copyrightBanCorp() {
      return this.bankcode === "002";
    },
    copyright() {
      const bank_code = this.bankcode;
      let copyright = i18n.global.t("TheFooter.copyright_default");
      if (bank_code) {
        for (let i = 0; i < copyrights.length; i++) {
          if (bank_code === copyrights[i].code) {
            copyright = copyrights[i].value;
            break;
          }
        }
      }
      return copyright;
    },
    showDisclaimer() {
      return (
        this.payoutStore.payoutTypeSelectedSource === "US_DEBIT" &&
        this.payoutStore.getFormCurrentLandingState === "US_DEBIT_FORM_1"
      );
    },
    disclaimerClass() {
      return this.showDisclaimer
        ? "center-align-footer-disclaimer"
        : "center-align-footer-noDisclaimer";
    },
    isErrorInRoute() {
      return (
        !this.bankcode &&
        (this.$route.path.indexOf("error") > 0 ||
          this.$route.path.indexOf("notfound") > 0 ||
          this.$route.path.indexOf("faq") > 0)
      );
    },
    copyrightBanCorpUrl() {
      return "https://disclosuregenerator.mynycb.com/api/disclosuredocument/Privacy.pdf";
    },
  },
};
</script>
<style scoped>
.main-footer {
  width: 100%;
}
.main-footer ul {
  list-style-type: none;
}

.main-footer li {
  margin-bottom: 8px;
}
.main-footer ul li a {
  text-decoration: none;
}

.pb-82 {
  padding-bottom: 82px;
}

.center-align-footer-noDisclaimer {
  margin: 0 auto;
}
.center-align-footer-disclaimer {
  margin: 0 auto;
}
.pl-0 li {
  margin-bottom: 0;
}
</style>
