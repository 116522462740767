<template>
  <div class="pa-3">
    <confirm-dialog
      v-model="showTermsDialog"
      :title="$t('VenmoForm.acceptTermsHeader')"
      :show-cancel-button="false"
      :confirm-button-text="$t('VenmoForm.acceptTermsContinue')"
      @input:confirm="showTermsDialog = false"
    >
      <i18n-t keypath="VenmoForm.acceptTermsMessage" tag="span">
        <a
          class="fiserv-dark-blue--text text-decoration-none"
          target="_blank"
          href="https://www.paypal.com/us/cshelp/article/tax-holds-%7C-backup-withholding-%7C-paypal-us-help936?Z3JncnB0="
          >{{ $t("VenmoForm.here") }}</a
        >
      </i18n-t>
    </confirm-dialog>

    <div class="my-8 text-center" data-test="header">
      <div class="primary--text">
        {{ $t("VenmoForm.note") }}
      </div>
      <read-more-tooltip bold>
        {{ getTooltipTimeFrame("VENMO", "") }}
      </read-more-tooltip>
    </div>
    <v-divider></v-divider>
    <v-form ref="form" v-model="isFormValid" :disabled="disableForm">
      <v-row no-gutters>
        <v-col class="pr-md-4 py-4 font-weight-bold">
          {{ $t("VenmoForm.disclaimer") }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="pr-md-4">
          <phone-number-field
            v-model="form.mobile"
            :label="$t('VenmoForm.mobile')"
            required
            data-test="mobile-no"
            prefix="+1"
            :rules="[required($t('VenmoForm.mobileNumberRequired'))]"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="pr-md-4">
          <phone-number-field
            v-model="form.confirmMobileNumber"
            prefix="+1"
            :label="$t('VenmoForm.confirmMobile')"
            data-test="confirm-mobile-no"
            :rules="[
              required($t('VenmoForm.mobileNumberRequired')),
              phoneNumbersMatch,
            ]"
            @paste.prevent
          />
        </v-col>
      </v-row>

      <terms-and-conditions-checkbox
        v-model="form.terms"
        data-test="accept-terms"
        payout-method-type="VENMO"
        class="payoutTermsCondition"
      />

      <div class="d-flex justify-center">
        <v-btn
          class="mr-4 mr-md-6 custom_min_width_100"
          tile
          :disabled="disburseLoading || disableForm"
          elevation="0"
          color="primary"
          data-test="add-venmo"
          :loading="disburseLoading"
          @click="submit"
          >{{ $t("VenmoForm.submit") }}
        </v-btn>

        <v-btn
          class="ml-4 ml-md-6 custom_min_width_100"
          tile
          variant="outlined"
          color="primary"
          data-test="cancel"
          @click="showConfirmCancel = true"
          >{{ $t("VenmoForm.cancel") }}
        </v-btn>
      </div>

      <div v-if="disableForm" class="d-flex px-3 pt-6 px-sm-4">
        <v-alert type="error" tile>
          <span data-test="disburse-error-message">
            {{ disburseError }}
          </span>
        </v-alert>
      </div>
    </v-form>

    <payout-methods-confirm-cancel-dialog v-model="showConfirmCancel" />
  </div>
</template>

<script>
import { mdiArrowTopRight, mdiEye, mdiEyeOff } from "@mdi/js";
import { mapState } from "pinia";
import PayoutMethodsConfirmCancelDialog from "@/components/payout-methods/PayoutMethodsConfirmCancelDialog";
import PhoneNumberField from "@/components/forms/PhoneNumberField";
import TermsAndConditionsCheckbox from "@/components/forms/TermsAndConditionsCheckbox";
import { useAuthStore } from "@/stores/auth";
import isEqual from "lodash/isEqual";
import { useTitle } from "@/composables/title";
import { required } from "@/utils/rules";
import { getTooltipTimeFrame } from "@/utils/payout";
import { i18n } from "@/plugins/i18n";
import { useDisburse } from "@/composables/disburse";
import { usePayoutMethodStore } from "@/stores/payout-method";
import ReadMoreTooltip from "@/components/payout-methods/ReadMoreTooltip";
import { useDynamicContent } from "@/composables/dynamic-content";
import { useBack } from "@/composables/back";
import NewPayoutMethods from "@/components/payout-methods/NewPayoutMethods";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "VenmoForm",

  components: {
    ReadMoreTooltip,
    PhoneNumberField,
    PayoutMethodsConfirmCancelDialog,
    TermsAndConditionsCheckbox,
    ConfirmDialog,
  },

  props: {
    showDialog: { type: Boolean, default: false },
  },

  setup() {
    const authStore = useAuthStore();

    const title = authStore.isGuest
      ? i18n.global.t("VenmoForm.addVenmo")
      : i18n.global.t("VenmoForm.venmo");
    useTitle(title);

    if (authStore.isGuest) {
      const { setContent } = useDynamicContent();
      useBack(() => setContent(NewPayoutMethods));
    }

    const { disburse, disburseLoading, disburseError, transferDisabled } =
      useDisburse();

    const payoutMethodStore = usePayoutMethodStore();

    return {
      disburse,
      disburseLoading,
      error: disburseError,
      disabled: transferDisabled,
      authStore,
      payoutMethodStore,
      required,
      getTooltipTimeFrame,
    };
  },

  data() {
    return {
      mdiArrowTopRight,
      mdiEyeOff,
      mdiEye,
      isFormValid: false,
      form: {
        mobile: null,
        confirmMobileNumber: null,
        terms: false,
        makeDefault: false,
      },
      showConfirmCancel: false,
      showTermsDialog: false,
    };
  },

  computed: {
    ...mapState(useAuthStore, ["user", "isUser"]),

    phoneNumbersMatch() {
      return (
        isEqual(this.form.mobile, this.form.confirmMobileNumber) ||
        i18n.global.t("VenmoForm.mobileNumbersDoNotMatch")
      );
    },

    disableForm() {
      return this.transferDisabled;
    },
  },

  created() {
    this.showTermsDialog = true;
    this.form.mobile = null;
  },

  methods: {
    apiSchema() {
      return {
        recipientProfileInfo: {
          recipientId: this.user.recipientId,
        },
        source: "VENMO",
        venmo: {
          phone: {
            value: this.form.mobile,
          },
        },
      };
    },

    async submit() {
      const valid = await this.$refs.form.validate();
      if (!valid.valid) return;

      this.payoutMethodStore.selected = this.apiSchema();
      this.disburse();
    },
  },
};
</script>
