<script>
import { mdiAlertOctagonOutline } from "@mdi/js";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import { useDynamicContent } from "@/composables/dynamic-content";
import NewPayoutMethods from "@/components/payout-methods/NewPayoutMethods";
import { usePayoutStore } from "@/stores/payout";
import { computed } from "vue";
import { useDialogPanelStore } from "@/stores/dialog-panel";

export default {
  setup() {
    // CONTINUE
    const { setContent } = useDynamicContent();
    const dialogPanelStore = useDialogPanelStore();
    const payoutStore = usePayoutStore();
    const disbursing = computed(() => !!payoutStore.selected);
    const getPayoutSrc = computed(() => {
      return payoutStore.getPayoutTypeSelectedSource;
    });

    const title =
      getPayoutSrc.value === "PREPAID" || getPayoutSrc.value === "MONEYNETWORK"
        ? i18n.global.t("MoneyNetworkEnrollDeclined.title")
        : i18n.global.t("UsdebitcardEnrollDeclined.title");

    useTitle(title);
    function onContinue() {
      if (disbursing.value) {
        setContent(NewPayoutMethods);
      } else {
        dialogPanelStore.hide();
      }
    }

    return {
      mdiAlertOctagonOutline,
      onContinue,
      getPayoutSrc,
    };
  },
};
</script>

<template>
  <div class="d-flex flex-column align-center">
    <VIcon color="error" size="75" class="mt-16" data-test="declined-icon">
      mdi-check-decagram-outline
    </VIcon>

    <p
      v-if="getPayoutSrc === 'PREPAID' || getPayoutSrc === 'MONEYNETWORK'"
      class="text-h4 my-8"
      data-test="declined-content"
    >
      {{ $t("MoneyNetworkEnrollDeclined.content") }}
    </p>

    <p
      v-if="getPayoutSrc === 'US_DEBIT'"
      class="text-h4 my-8"
      data-test="declined-content"
    >
      {{ $t("UsdebitcardEnrollDeclined.content") }}
    </p>
    <VBtn
      variant="outlined"
      tile
      data-test="declined-continue-btn"
      @click="onContinue"
    >
      {{ $t("MoneyNetworkEnrollDeclined.continueBtn") }}
    </VBtn>
  </div>
</template>
